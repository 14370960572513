import React from "react";
import { API } from "@aws-amplify/api";

export function useQuery<T>() {
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState<T[]>(new Array());

  return {
    loading,
    setLoading,
    items,
    setItems,
  };
}

export function queryListWithCallback(
  queryOperation: { query: string; variables: any },
  onLoad: () => void,
  onNewItemsLoaded: (items: any[], errors: any[]) => void,
  onComplete: () => void,
  nextToken?: string
) {
  if (!nextToken) {
    onLoad();
  }

  if (
    !API ||
    typeof API.graphql !== "function" ||
    typeof API.getGraphqlOperationType !== "function"
  ) {
    throw new Error(
      "No API module found, please ensure @aws-amplify/api is imported"
    );
  }

  const hasValidQuery =
    queryOperation &&
    API.getGraphqlOperationType(queryOperation.query) === "query";

  if (!hasValidQuery) {
    console.warn("No query was specified");
  }

  if (hasValidQuery) {
    if (nextToken) {
      if (!queryOperation.variables) {
        queryOperation.variables = {};
      }
      queryOperation.variables.nextToken = nextToken;
    }

    (API.graphql(queryOperation) as Promise<any>)
      .then((response) => {
        let newData: any = null;
        let newErrors: any[] = [];

        newData = response.data;
        onNewItemsLoaded(newData.result.items, newErrors);

        if (newData.result.nextToken) {
          queryListWithCallback(
            queryOperation,
            onLoad,
            onNewItemsLoaded,
            onComplete,
            newData.result.nextToken
          );
        } else {
          onComplete();
        }
      })
      .catch((err) => {
        console.log(err);
        onNewItemsLoaded([], err.errors);
      });
  }
}

export async function queryList(
  queryOperation: { query: string; variables: any },
  items?: any[],
  nextToken?: string | null,
  single: boolean = false
): Promise<any> {
  if (
    !API ||
    typeof API.graphql !== "function" ||
    typeof API.getGraphqlOperationType !== "function"
  ) {
    throw new Error(
      "No API module found, please ensure @aws-amplify/api is imported"
    );
  }

  const hasValidQuery =
    queryOperation &&
    API.getGraphqlOperationType(queryOperation.query) === "query";

  if (!hasValidQuery) {
    console.warn("No query was specified");
  }

  if (!items) {
    items = [];
  }

  let newData: any = null;
  if (hasValidQuery) {
    if (nextToken) {
      if (!queryOperation.variables) {
        queryOperation.variables = {};
      }
      queryOperation.variables.nextToken = nextToken;
    }

    const response: any = await API.graphql(queryOperation);

    newData = response.data;
  }

  if (!newData || !newData.result) {
    throw Error("No data found in query.");
  }

  items = items.concat(newData.result.items);

  if (!single && newData.result.nextToken) {
    return queryList(queryOperation, items, newData.result.nextToken, single);
  } else if (single) {
    return newData.result;
  } else {
    return items;
  }
}
