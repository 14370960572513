import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

import ScrollToTopImage from "../assets/images/upArrow.png";

const ScrollToTop = (props: any) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location.pathname]);

  useEffect(() => {
    $(window).scroll(() => {
      var scrollTop = $(window).scrollTop();
      if (!scrollTop) {
        scrollTop = 0;
      }

      if (scrollTop > 100) {
        $("#scrollTop:hidden").stop(true, true).fadeIn();
      } else {
        $("#scrollTop").stop(true, true).fadeOut();
      }
    });
  }, []);

  return (
    <div id="scrollTop">
      <a onClick={() => window.scrollTo(0, 0)}>
        <img src={ScrollToTopImage} width="40" />
      </a>
    </div>
  );
};

export default withRouter(ScrollToTop);
