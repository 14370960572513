import React, { useEffect } from "react";
import { API } from "aws-amplify";
import { listCommunitys } from "scs-sdk/src/managers/CommunitysManager";
import { v4 } from "uuid";
import imageNotAvailable from "../assets/images/imageNotAvailable.png";
import { UserContext } from "./UserContext";
import { getCachedAPI } from "../libs/CacheManager";
import { useHistory, useLocation } from "react-router-dom";

interface IState {
  siteTitle: string;
  navbar: any[];
  navbarMobile: any[];
  footer: any[];
  banners: any[];
  communities: {
    all: any[];
    chapters: any[];
    sigs: any[];
    others: any[];
  };
  fullPage: boolean;
  hidePageTitle: boolean;
  setFullPage: any;
  setHidePageTitle: any;
}

const initialState = {
  siteTitle: "",
  navbar: [] as any[],
  navbarMobile: [] as any[],
  footer: [] as any[],
  banners: [] as any[],
  communities: {
    all: [] as any[],
    chapters: [] as any[],
    sigs: [] as any[],
    others: [] as any[],
  },
  fullPage: false,
  hidePageTitle: false,
} as IState;

export const WebsiteContext = React.createContext(initialState);

export const WebsiteContextProvider = (props: any) => {
  const history = useHistory();
  const [loading, isLoading] = React.useState(true);
  const { isAuthenticated, getERPLink } = React.useContext(UserContext);
  const [siteTitle, setSiteTitle] = React.useState("");
  const [navbar, setNavbar] = React.useState<any[]>([]);
  const [navbarMobile, setNavbarMobile] = React.useState<any[]>([]);
  const [footer, setFooter] = React.useState<any[]>([]);
  const [banners, setBanners] = React.useState<any[]>([]);
  const [communities, setCommunities] = React.useState<{
    all: any[];
    chapters: any[];
    sigs: any[];
    others: any[];
  }>({
    all: [] as any[],
    chapters: [] as any[],
    sigs: [] as any[],
    others: [] as any[],
  });
  const [fullPage, setFullPage] = React.useState<boolean>(false);
  const [hidePageTitle, setHidePageTitle] = React.useState<false>(false);

  const init = async () => {
    isLoading(true);

    let configurations = await getCachedAPI<{
      data: {
        title?: { data: string };
        navbar?: { data: string };
        navbarMobile?: { data: string };
        footer?: { data: string };
        banners?: { data: string };
      };
    }>("configurations", {
      callback: () => {
        return API.graphql({
          query: `
          query {
              title: getConfiguration(id: "title") {
                  data
              }
              navbar: getConfiguration(id: "navigationbar") {
                  data
              }
              navbarMobile: getConfiguration(id: "navigationbarmobile") {
                  data
              }
              footer: getConfiguration(id: "footer") {
                  data
              }
              banners: getConfiguration(id: "banners") {
                  data
              }
          }
      `,
        }) as any;
      },
    });

    const results = configurations.data;

    if (results.title) {
      setSiteTitle(results.title.data);
    } else {
      setSiteTitle("My Website");
    }

    let _navbar = [];
    if (results.navbar) {
      _navbar = JSON.parse(results.navbar.data);
      setNavbar(_navbar);
    }

    let _navbarMobile = [];
    if (results.navbarMobile) {
      _navbarMobile = JSON.parse(results.navbarMobile.data);
      setNavbarMobile(_navbarMobile);
    }

    if (results.footer) {
      setFooter(JSON.parse(results.footer.data));
    }

    let _banners = [];
    if (results.banners) {
      _banners = JSON.parse(results.banners.data);
    }

    const _communities = await listCommunitys(
      [
        {
          items: [
            "id",
            "name",
            "slug",
            { seo: ["title", { metas: ["content,name"] }] },
            { tags: ["name"] },
            "tags_text",
            "committee",
            { thumbnail: ["key", "region", "bucket"] },
            { content: ["key", "region", "bucket"] },
            { anchorEvent: ["key", "region", "bucket"] },
            "version",
            "description",
            "category",
          ],
        },
      ],
      {
        filter: {
          deleted: {
            eq: false,
          },
          status: {
            eq: "APPROVED",
          },
        },
      }
    );

    const processedData = await processData(
      _navbar,
      _navbarMobile,
      _banners,
      _communities
    );

    setNavbar(processedData.navbar);
    setNavbarMobile(processedData.navbarMobile);
    setBanners(processedData.banners);
    setCommunities(processedData.communities);
    isLoading(false);
  };

  const processData = async (
    navbar: any[],
    navbarMobile: any[],
    banners: any[],
    communities: any[]
  ) => {
    const _sort = (a: any, b: any) => {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    };

    for (const community of communities) {
      if (community.thumbnail) {
        community.thumbnailUrl = `https://${community.thumbnail.bucket}.s3-${community.thumbnail.region}.amazonaws.com/public/${community.thumbnail.key}`;
      }
      if (community.committee) {
        community.committee = (JSON.parse(community.committee) as any[]).map(
          (committeeMember) => {
            if (committeeMember && committeeMember.profile) {
              committeeMember.imageUrl = `https://${committeeMember.profile.bucket}.s3-${committeeMember.profile.region}.amazonaws.com/public/${committeeMember.profile.key}`;
            } else {
              committeeMember.imageUrl = imageNotAvailable;
            }
            return committeeMember;
          }
        );
      }
    }

    const chapters = communities
      .filter((community: any) => community.category === "CHAPTERS")
      .sort(_sort);
    const sigs = communities
      .filter((community: any) => community.category === "SIGS")
      .sort(_sort);
    const others = communities
      .filter((community: any) => community.category === "OTHERS")
      .sort(_sort);

    const copNavbar = navbar.find(
      (item) => item.title.toUpperCase() === "COMMUNITIES OF PRACTICE"
    );

    if (copNavbar) {
      copNavbar.children = [] as any[];

      const chapterNavbar = {
        id: v4(),
        title: "Chapters",
        href: "/communities",
        label: "",
        labelColor: "",
        children: [] as any[],
      };

      for (const chapter of chapters) {
        chapterNavbar.children.push({
          id: v4(),
          title: chapter.name,
          href: chapter.slug,
          label: "",
          labelColor: "",
          children: [],
        });
      }

      copNavbar.children.push(chapterNavbar);

      const sigsNavbar = {
        id: v4(),
        title: "Special Interest Groups",
        href: "/communities",
        label: "",
        labelColor: "",
        children: [] as any[],
      };

      for (const sig of sigs) {
        sigsNavbar.children.push({
          id: v4(),
          title: sig.name,
          href: sig.slug,
          label: "",
          labelColor: "",
          children: [],
        });
      }

      copNavbar.children.push(sigsNavbar);

      const othersNavbar = {
        id: v4(),
        title: "Other",
        href: "/communities",
        label: "",
        labelColor: "",
        children: [] as any[],
      };

      for (const other of others) {
        othersNavbar.children.push({
          id: v4(),
          title: other.name,
          href: other.slug,
          label: "",
          labelColor: "",
          children: [],
        });
      }

      copNavbar.children.push(othersNavbar);

      copNavbar.children.push({
        id: v4(),
        title: "Not Involved Yet?",
        href: "#",
        label: "",
        labelColor: "",
        children: [
          {
            id: "59788b4c-0af1-42a3-b478-f1b77dde1b22",
            title: "Join a community today!",
            href: "/communities",
            label: "",
            labelColor: "rgba(255, 0, 0, 1)",
            children: [],
          },
        ],
      });
    }

    const copNavbarMobile = navbarMobile.find(
      (item) => item.title === "COMMUNITIES OF PRACTICE"
    );

    if (copNavbarMobile) {
      copNavbarMobile.children = [] as any[];

      const chapterNavbarMobile = {
        id: v4(),
        title: "Chapters",
        href: "#",
        label: "",
        labelColor: "",
        children: [] as any[],
      };

      for (const chapter of chapters) {
        chapterNavbarMobile.children.push({
          id: v4(),
          title: chapter.name,
          href: chapter.slug,
          label: "",
          labelColor: "",
          children: [],
        });
      }

      copNavbarMobile.children.push(chapterNavbarMobile);

      const sigsNavbarMobile = {
        id: v4(),
        title: "Special Interest Groups",
        href: "#",
        label: "",
        labelColor: "",
        children: [] as any[],
      };

      for (const sig of sigs) {
        sigsNavbarMobile.children.push({
          id: v4(),
          title: sig.name,
          href: sig.slug,
          label: "",
          labelColor: "",
          children: [],
        });
      }

      copNavbarMobile.children.push(sigsNavbarMobile);

      const othersNavbarMobile = {
        id: v4(),
        title: "Other",
        href: "#",
        label: "",
        labelColor: "",
        children: [] as any[],
      };

      for (const other of others) {
        othersNavbarMobile.children.push({
          id: v4(),
          title: other.name,
          href: other.slug,
          label: "",
          labelColor: "",
          children: [],
        });
      }

      copNavbarMobile.children.push(othersNavbarMobile);

      copNavbarMobile.children.push({
        id: v4(),
        title: "Not Involved Yet?",
        href: "#",
        label: "",
        labelColor: "",
        children: [
          {
            id: v4(),
            title: "Join a community today!",
            href: "/communities",
            label: "",
            labelColor: "",
            children: [],
          },
        ],
      });
    }

    for (const banner of banners) {
      banner.imageUrl = `https://${banner.bucket}.s3-${banner.region}.amazonaws.com/public/${banner.key}`;
    }

    return {
      navbar,
      navbarMobile,
      banners,
      communities: {
        all: communities,
        chapters,
        sigs,
        others,
      },
    };
  };

  useEffect(() => {
    init();
  }, [isAuthenticated]);

  useEffect(() => {
    history.listen((location, action) => {
      setFullPage(false);
      setHidePageTitle(false);
    });
  }, [history]);

  if (loading) {
    return null;
  }

  return (
    <WebsiteContext.Provider
      value={{
        siteTitle,
        navbar,
        navbarMobile,
        footer,
        banners,
        communities,
        fullPage,
        hidePageTitle,
        setFullPage,
        setHidePageTitle,
      }}
    >
      {props.children}
    </WebsiteContext.Provider>
  );
};
