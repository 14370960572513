import React from "react";
import { Container, Row, Col, Breadcrumb, Button } from "react-bootstrap";
import { WebsiteContext } from "../contexts/WebsiteContext";
import Helmet from "react-helmet";
import { NavHashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

interface IContentHeaderProps {
  media: {
    breadcrumbs?: IBreadcrumb[];
    seo: {
      title: string;
      metas?: {
        name: string;
        content: string;
      }[];
    };
    sidebar?:
      | {
          items: {
            label: string;
            href: string;
          }[];
        }
      | any;
    tags?: {
      name: string;
    }[];
    thumbnail?: {
      key: string;
      region: string;
      bucket: string;
    };
  };
}

const ContentHeader: React.FC<IContentHeaderProps> = (props) => {
  const { siteTitle, fullPage } = React.useContext(WebsiteContext);
  const { media } = props;
  const [showAllOptions, setShowAllOptions] = React.useState(false);

  let mobileMenu = media.sidebar ? media.sidebar.items : undefined;

  if (media.sidebar && media.sidebar.items && !showAllOptions) {
    mobileMenu = media.sidebar.items.slice(
      0,
      media.sidebar.items.length >= 3 ? 2 : media.sidebar.items.length
    );
  }

  const onSeeMoreClicked = () => {
    setShowAllOptions(!showAllOptions);
  };

  return (
    <>
      <Helmet>
        <title>
          {media.seo.title} - {siteTitle}
        </title>
        {media.seo.title !== "PAGE NOT FOUND" && (
          <meta property="og:url" content={window.location.href} />
        )}
        {media.seo.title !== "PAGE NOT FOUND" && (
          <meta property="og:title" content={media.seo.title} />
        )}
        {media.seo.title !== "PAGE NOT FOUND" && media.thumbnail && (
          <meta
            property="og:image"
            content={`https://${media.thumbnail.bucket}.s3-${media.thumbnail.region}.amazonaws.com/public/${media.thumbnail.key}`}
          />
        )}
        {media.seo.title === "PAGE NOT FOUND" && (
          <meta name="prerender-status-code" content="404" />
        )}
        {media.seo.metas && media.seo.metas.length > 0
          ? media.seo.metas.map((item: any, index: number) => (
              <meta name={item.name} content={item.content} key={index} />
            ))
          : null}
      </Helmet>
      <Container fluid={fullPage} className="my-3">
        <Row>
          <Col>
            {media.breadcrumbs ? (
              <Breadcrumb
                className="mt-2"
                listProps={{ className: "bg-transparent p-0" }}
              >
                {media.breadcrumbs.map((breadcrumb: any, i: number) => (
                  <Breadcrumb.Item
                    linkAs={Link}
                    linkProps={
                      {
                        to: breadcrumb.href,
                      } as any
                    }
                    key={i}
                  >
                    {breadcrumb.title}
                  </Breadcrumb.Item>
                ))}
                <Breadcrumb.Item active>{props.children}</Breadcrumb.Item>
              </Breadcrumb>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-primary mb-3">
              <h1 className="text-letter-spacing-1 text-uppercase fw-bold">
                {media.seo.title}
              </h1>
            </div>
          </Col>
        </Row>
        <Row className="mb-2 d-flex d-lg-none">
          <Col className="m-1">
            {mobileMenu &&
              mobileMenu.map((item: any, i: number) => (
                <Button
                  variant="outline-primary"
                  as={NavHashLink}
                  to={item.href}
                  exact
                  className="m-1"
                  key={i}
                >
                  {item.label}
                </Button>
              ))}
            {mobileMenu && mobileMenu.length < media.sidebar.items.length && (
              <Button
                variant="outline-secondary"
                onClick={onSeeMoreClicked}
                className="m-1"
              >
                See more
              </Button>
            )}
            {mobileMenu && mobileMenu.length === media.sidebar.items.length && (
              <Button
                variant="outline-secondary"
                onClick={onSeeMoreClicked}
                className="m-1"
              >
                Hide
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContentHeader;
