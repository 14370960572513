import React, { useEffect } from "react";
import { CONFIG } from "scs-sdk";
import { getProfile } from "scs-sdk/src/graphql/queries";

import { API, Auth } from "aws-amplify";
import { graphqlOperation } from "@aws-amplify/api";
import { BrowserStorageCache } from "@aws-amplify/cache";
import { getERPLink as _getERPLink } from "scs-sdk/src/managers/ERPManager";

interface IState {
  isLoading: boolean;
  isAuthenticated: boolean;
  user?: any;
  signIn: (username: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  getERPLink: (path: string, redirect_url?: string) => Promise<string | null>;
  reloadProfile: () => Promise<void>;
}

interface IAction {
  type: string;
  payload?: any;
}

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  user: undefined,
} as IState;

export const UserContext = React.createContext(initialState);

const reducer = (state: IState, action: IAction) => {
  switch (action.type) {
    case "onUserLoggedIn":
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case "onUserLoggedOut":
      return initialState;
    default:
      return state;
  }
};

export const UserContextProvider = (props: any) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const _getProfile = async () => {
    const profile: any = await API.graphql(graphqlOperation(getProfile));
    const parsedProfile = JSON.parse(profile.data.getProfile);

    return parsedProfile;
  };

  const signIn = async (username: string = "", password: string = "") => {
    let user = null;

    if (username === "" && password === "") {
      try {
        user = await Auth.currentAuthenticatedUser();
      } catch (err: any) {
        await signOut();
        return;
      }
    }

    if (!user) {
      user = await Auth.signIn(username!, password!);
    }

    CONFIG.aws_appsync_authenticationType = "AMAZON_COGNITO_USER_POOLS";
    API.configure({
      url: CONFIG.aws_appsync_graphqlEndpoint,
      region: CONFIG.aws_appsync_region,
      graphql_headers: async () => {
        const session = await Auth.currentSession();

        return {
          Authorization: session.getIdToken().getJwtToken(),
        };
      },
    });

    const profile = await _getProfile();
    if (profile.statusCode === 400 || profile.statusCode === 1) {
      await signOut();
      throw Error("Error Code 1");
    } else {
      dispatch({
        type: "onUserLoggedIn",
        payload: {
          user: JSON.parse(profile.body),
        },
      });
    }
    return user;
  };

  const signOut = async () => {
    try {
      await Auth.signOut();

      CONFIG.aws_appsync_authenticationType = "AWS_IAM";
      API.configure(CONFIG);

      await Auth.currentCredentials();

      BrowserStorageCache.removeItem("rememberMe");

      dispatch({
        type: "onUserLoggedOut",
      });
    } catch (err: any) {
      console.log(err);
      alert(err.message);
    }
  };

  const getERPLink = async (path: string, redirect_url?: string) => {
    const erpLink = await _getERPLink({
      input: {
        link_page: path,
        redirect_url: redirect_url ? redirect_url : window.location.href,
      },
    });
    return erpLink;
  };

  const reloadProfile = async () => {
    const profile = await _getProfile();
    if (profile.statusCode === 400 || profile.statusCode === 1) {
      await signOut();
      throw Error("Error Code 1");
    } else {
      dispatch({
        type: "onUserLoggedIn",
        payload: {
          user: JSON.parse(profile.body),
        },
      });
    }
  };

  const init = async () => {
    try {
      const rememberMe = await BrowserStorageCache.getItem("rememberMe");

      if (!rememberMe || process.env.REACT_APP_ERP_ENABLED !== "true") {
        await signOut();
        return;
      } else {
        BrowserStorageCache.setItem("rememberMe", true, {
          expires: new Date().getTime() + 1000 * 60 * 60 * 24 * 30,
        });
        await signIn();
      }
    } catch {}
  };

  useEffect(() => {
    init();
  }, []);

  if (state.isLoading) {
    return null;
  }

  return (
    <UserContext.Provider
      value={{
        ...state,
        signIn,
        signOut,
        getERPLink,
        reloadProfile,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
