import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Page from "./components/Page";
import SuspenseComponent from "./SuspenseComponent";

const routes: {
  path?: string;
  component?: {
    path: string;
    props?: { [key: string]: any };
  };
  exact?: boolean;
}[] = [
  {
    path: "/",
    component: {
      path: "./pages/Home/Home",
    },
    exact: true,
  },
  {
    path: "/communities",
    component: {
      path: "./components/PageCommunities",
    },
    exact: true,
  },
  {
    path: "/communities/:community",
    component: {
      path: "./components/ContainerCommunitys",
    },
    exact: true,
  },
  {
    path: "/communities/:community/:page",
    component: {
      path: "./components/ContainerCommunitys",
    },
    exact: true,
  },
  {
    path: "/events/upcoming",
    component: {
      path: "./pages/Events/Events",
    },
    exact: true,
  },
  {
    path: "/events/past",
    component: {
      path: "./pages/Events/Events",
      props: {
        past: true,
      },
    },
    exact: true,
  },
  {
    path: "/events/supporting",
    component: {
      path: "./pages/Events/Events",
      props: {
        supporting: true,
      },
    },
    exact: true,
  },
  {
    path: "/events/register/:slug",
    component: {
      path: "./pages/Events/EventRegistration",
    },
    exact: true,
  },
  {
    path: "/events/register-new/:slug",
    component: {
      path: "./pages/Events/EventRegistrationNew",
    },
    exact: true,
  },
  {
    path: "/events/details/:slug",
    component: {
      path: "./pages/Events/Event",
    },
    exact: true,
  },
  {
    path: "/resources",
    component: {
      path: "./pages/Resources/Resources",
    },
    exact: true,
  },
  {
    path: "/resources/:tag",
    component: {
      path: "./pages/Resources/ResourcesTags",
    },
    exact: true,
  },
  {
    path: "/account/logout",
    component: {
      path: "./pages/Logout",
    },
    exact: true,
  },
  {
    path: "/account/past-purchases",
    component: {
      path: "./components/PastPurchase", // dead link
    },
    exact: true,
  },
  {
    path: "/account/reset-password",
    component: {
      path: "./pages/ResetPassword",
    },
    exact: true,
  },
  {
    path: "/account/change-password",
    component: {
      path: "./pages/ChangePassword",
    },
    exact: true,
  },
  {
    path: "/account/change-password/result",
    component: {
      path: "./pages/ChangePasswordResult",
    },
    exact: true,
  },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Switch>
      {routes.map((route, i) => (
        <Route
          key={i}
          path={route.path}
          render={(props) => {
            if (route.component) {
              return (
                <SuspenseComponent
                  componentPath={route.component.path}
                  props={{ ...props, ...route.component.props }}
                />
              );
            }

            return null;
          }}
          exact={route.exact}
        />
      ))}
      <Route
        path="/events"
        exact
        render={() => <Redirect to="/events/upcoming" />}
      />
      <Route path="/events/anchor" exact component={Page} />
      <Route path="/events/scs-tech-series" exact component={Page} />
      <Route
        path="/events"
        exact
        render={() => <Redirect to="/events/upcoming" />}
      />
      <Route
        path="/events/:slug"
        exact
        render={(props) => (
          <Redirect to={`/events/details/${props.match.params.slug}`} />
        )}
      />
      <Route
        path="/account"
        exact
        render={() => <Redirect to="/account/profile" />}
      />
      <Route component={Page} />
    </Switch>
  );
};
