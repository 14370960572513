import React, { ReactNode, ReactElement } from "react";
import ContentHeader from "./ContentHeader";
import Sidebar from "./Sidebar/Sidebar";
import { Row, Container, Col } from "react-bootstrap";
import { WebsiteContext } from "../contexts/WebsiteContext";

interface ICustomProps {
  page: {
    breadcrumbs?: IBreadcrumb[];
    seo: {
      title: string;
      metas?: {
        name: string;
        content: string;
      }[];
    };
    sidebar?: TSidebar | any;
  };
}

const PageTemplate: React.FC<ICustomProps> = (props) => {
  const { page, children } = props;

  const { fullPage, hidePageTitle } = React.useContext(WebsiteContext);
  return (
    <div>
      {!hidePageTitle && <ContentHeader media={page} />}
      <Container fluid={fullPage}>
        <Row className="d-flex flex-nowrap">
          {page.sidebar && page.sidebar.items ? (
            <Col md="auto" className="d-none d-lg-flex">
              <div className="w-100">
                <Sidebar
                  sidebarArray={page.sidebar.items.map((item: any) => {
                    item.name = item.label;
                    item.slug = item.href;
                    return item;
                  })}
                />
              </div>
            </Col>
          ) : page.sidebar ? (
            <Col md="auto" className="d-none d-md-flex">
              {page.sidebar}
            </Col>
          ) : null}

          <Col className="flex-shrink-0 text-wrap overflow-auto">{children}</Col>
        </Row>
      </Container>
    </div>
  );
};

export default PageTemplate;
