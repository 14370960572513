import React from "react";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

interface IProps {
  sidebarArray: Array<{ slug: string; name: string; id: string }>;
  title?: string;
}

export const Sidebar: React.FC<IProps> = (props) => {
  const { pathname, search } = useLocation();

  let mode = undefined;

  if (pathname.startsWith("/awards/splash/2021")) {
    mode = "splash-2021";
  } else if (pathname.startsWith("/awards/splash/2022")) {
    mode = "splash-2022";
  } else if (pathname.startsWith("/awards/sg100wit")) {
    mode = "wit-list";
  }

  let bgStyle: any = undefined;
  let ulStyle: any = { color: "#FFF", paddingLeft: 0, listStyle: "none" };
  let liStyle: any = undefined;
  let linkStyle: any = undefined;

  if (mode === "splash-2021") {
    bgStyle = { background: "#150FA3" };
    ulStyle = { paddingLeft: 0, listStyle: "none" };
    liStyle = { borderBottom: "1px solid #00EEA5" };
    linkStyle = { color: "#FFF" };
  } else if (mode === "splash-2022") {
    bgStyle = { background: "#000" };
    ulStyle = { paddingLeft: 0, listStyle: "none" };
    liStyle = { borderBottom: "1px solid #000" };
    linkStyle = { color: "#FFF" };
  } else if (mode === "wit-list") {
    bgStyle = { background: "#532B71" };
    ulStyle = { paddingLeft: 0, listStyle: "none" };
    liStyle = { borderBottom: "1px solid #E12364" };
    linkStyle = { color: "#FFF" };
  }

  const { sidebarArray, title } = props;

  return (
    <div id={mode} className="sidebar" style={bgStyle}>
      <ul style={ulStyle}>
        {title ? (
          <li>
            <h5>{title}</h5>
          </li>
        ) : null}
        {sidebarArray.map((item: any, i: number) => {
          if (item.slug.startsWith("#") || item.slug.includes("#")) {
            return (
              <li key={i} style={liStyle}>
                <HashLink smooth to={item.slug + search} style={linkStyle}>
                  {item.name}
                </HashLink>
              </li>
            );
          } else if (item.slug.startsWith("http")) {
            return (
              <li key={i} style={liStyle}>
                <a
                  href={item.slug}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={linkStyle}
                >
                  {item.name}
                </a>
              </li>
            );
          } else {
            return (
              <li key={i} style={liStyle}>
                <Link to={item.slug + search} style={linkStyle}>
                  {item.name}
                </Link>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default Sidebar;
