import React, { useState, useEffect } from "react";
import { API } from "@aws-amplify/api";
import Storage from "@aws-amplify/storage";
import LoadingIndicator from "./LoadingIndicator";
import { getMediaBySlugQuery } from "scs-sdk/src/managers/PagesManager";
import { RouteComponentProps, Link } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import PageTemplate from "./PageTemplate";
import { Row, Col, Alert, Badge } from "react-bootstrap";
import { RenderHTML } from "./RenderHTML";
import { ListMediasBySlugQueryVariables } from "scs-sdk/src/API";
import moment from "moment";
import {
  FacebookShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  FacebookIcon,
  FacebookShareCount,
  TelegramIcon,
  WhatsappIcon,
} from "react-share";

const Page: React.FC<RouteComponentProps> = (props) => {
  const {
    location: { pathname: path },
  } = props;

  const { isAuthenticated } = React.useContext(UserContext);
  const notFoundHtml =
    "<p class='my-2'>The link you have entered does not exist. Click <a href='/'>here</a> to go back to homepage.</p>";
  const [page, setPage] = useState<any>(undefined);
  const [isPreview, setIsPreview] = useState(false);
  const [loading, setLoading] = useState(true);

  let fixedPath = path.toLowerCase();

  if (fixedPath.endsWith("/")) {
    fixedPath = fixedPath.slice(0, -1);
  }

  let isDigitalResource =
    fixedPath.startsWith("/videos/") || fixedPath.startsWith("/articles/");

  const variables: ListMediasBySlugQueryVariables = {
    slug: fixedPath,
    publishAt: {
      lt: new Date().getTime(),
    },
    filter: {
      deleted: {
        eq: false,
      },
    },
  };

  const query = new URLSearchParams(props.location.search);
  const draftQuery = query.get("v");
  if (draftQuery !== "draft") {
    variables.filter!.status = {
      eq: "APPROVED",
    };
  }

  const _getPageQuery = getMediaBySlugQuery(
    [
      {
        items: [
          "slug",
          "type",
          "publishAt",
          { seo: ["title", { metas: ["name", "content"] }] },
          { tags: ["name"] },
          { content: ["key", "region", "bucket"] },
          { preview: ["key", "region", "bucket"] },
          { sidebar: [{ items: ["label", "href"] }] },
          { thumbnail: ["key", "region", "bucket"] },
        ],
      },
      "nextToken",
    ],
    variables
  );

  const init = async () => {
    if (isAuthenticated && !isPreview && page && page.slug === path) {
      return;
    }
    setLoading(true);

    let _page;
    const res: any = await API.graphql(_getPageQuery);
    if (res.data.result.items && res.data.result.items.length > 0) {
      _page = res.data.result.items[0];
      let key = undefined;

      if (!_page.content) {
        key = _page.preview.key;
        setIsPreview(true);
      } else {
        key = _page.content.key;
        setIsPreview(false);
      }

      const pageContentBlob: any = await Storage.get(key, {
        download: true,
      });

      _page.html = (await new Response(pageContentBlob.Body).text()) || " ";
    } else {
      _page = {
        html: notFoundHtml,
        seo: { title: "PAGE NOT FOUND" },
      };

      isDigitalResource = false;
    }

    setPage(_page);
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, [path, isAuthenticated]);

  if (loading || !page) {
    return <LoadingIndicator />;
  }
  return (
    <PageTemplate page={page}>
      {draftQuery === "draft" ? (
        <Row>
          <Col>
            <Alert variant="info">
              You are previewing the draft of this page. This page is not
              published yet.
            </Alert>
          </Col>
        </Row>
      ) : null}
      {page.publishAt && isDigitalResource ? (
        <>
          <Row className="p-2 mb-2">
            <Col xs="auto">
              {page.tags ? (
                <>
                  <span>Tags: </span>
                  {page.tags.map((tag: any, i: number) => (
                    <Badge key={i} className="mx-1" bg="secondary">
                      <Link
                        to={
                          "/resources/" +
                          tag.name.replace(/ /g, "-").toLowerCase()
                        }
                        className="h6 text-white"
                      >
                        {tag.name}
                      </Link>
                    </Badge>
                  ))}
                </>
              ) : null}
            </Col>
            <Col></Col>
            <Col xs="auto">
              <div className="text-end">
                Published {moment(page.publishAt).fromNow()}
              </div>
            </Col>
          </Row>
          <Row className="p-2 mb-2">
            <Col xs="auto">
              <FacebookShareButton url={window.location.href}>
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
              <FacebookShareCount url={window.location.href} />
            </Col>
            {/* <Col xs="auto">
          <LinkedinShareButton url={window.location.href}>
            <LinkedinIcon size={32} round={true} />
          </LinkedinShareButton>
        </Col> */}
            <Col xs="auto">
              <TelegramShareButton url={window.location.href}>
                <TelegramIcon size={32} round={true} />
              </TelegramShareButton>
            </Col>
            <Col xs="auto">
              <WhatsappShareButton url={window.location.href}>
                <WhatsappIcon size={32} round={true} />
              </WhatsappShareButton>
            </Col>
          </Row>
        </>
      ) : null}
      {isPreview ? (
        <Row>
          <Col>
            <Alert variant="info">
              You are viewing the preview of this page. For the full content,
              please{" "}
              <Link
                to={
                  "/account/login?redirect=" +
                  (path !== "/account/login"
                    ? path + window.location.search
                    : "/")
                }
              >
                login
              </Link>{" "}
              to the Member Portal.
            </Alert>
          </Col>
        </Row>
      ) : null}
      <RenderHTML html={page.html} />
    </PageTemplate>
  );
};

export default Page;
