/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getProfileByToken = /* GraphQL */ `query GetProfileByToken($token: String!) {
  getProfileByToken(token: $token)
}
` as GeneratedQuery<
  APITypes.GetProfileByTokenQueryVariables,
  APITypes.GetProfileByTokenQuery
>;
export const getBOKOTP = /* GraphQL */ `query GetBOKOTP($input: GetBOKOTPQueryInput!) {
  getBOKOTP(input: $input)
}
` as GeneratedQuery<APITypes.GetBOKOTPQueryVariables, APITypes.GetBOKOTPQuery>;
export const getConfiguration = /* GraphQL */ `query GetConfiguration($id: ID!) {
  getConfiguration(id: $id) {
    id
    name
    data
    deleted
    version
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetConfigurationQueryVariables,
  APITypes.GetConfigurationQuery
>;
export const listConfigurations = /* GraphQL */ `query ListConfigurations(
  $filter: ModelConfigurationFilterInput
  $limit: Int
  $nextToken: String
) {
  listConfigurations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      data
      deleted
      version
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListConfigurationsQueryVariables,
  APITypes.ListConfigurationsQuery
>;
export const getCommunity = /* GraphQL */ `query GetCommunity($id: ID!) {
  getCommunity(id: $id) {
    id
    name
    committee
    anchorEvent {
      key
      region
      bucket
      __typename
    }
    category
    description
    slug
    tags {
      id
      name
      __typename
    }
    tags_text
    publishAt
    preview {
      key
      region
      bucket
      __typename
    }
    isPrivate
    canAccessContent
    content {
      key
      region
      bucket
      __typename
    }
    thumbnail {
      key
      region
      bucket
      __typename
    }
    seo {
      title
      __typename
    }
    status
    type
    deleted
    version
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCommunityQueryVariables,
  APITypes.GetCommunityQuery
>;
export const listCommunitys = /* GraphQL */ `query ListCommunitys(
  $filter: ModelCommunityFilterInput
  $limit: Int
  $nextToken: String
) {
  listCommunitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      committee
      category
      description
      slug
      tags_text
      publishAt
      isPrivate
      canAccessContent
      status
      type
      deleted
      version
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommunitysQueryVariables,
  APITypes.ListCommunitysQuery
>;
export const listCommunitiesBySlug = /* GraphQL */ `query ListCommunitiesBySlug(
  $slug: String
  $sortDirection: ModelSortDirection
  $filter: ModelCommunityFilterInput
  $limit: Int
  $nextToken: String
) {
  listCommunitiesBySlug(
    slug: $slug
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      committee
      category
      description
      slug
      tags_text
      publishAt
      isPrivate
      canAccessContent
      status
      type
      deleted
      version
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommunitiesBySlugQueryVariables,
  APITypes.ListCommunitiesBySlugQuery
>;
export const listEventsBySlug = /* GraphQL */ `query ListEventsBySlug(
  $slug: String
  $sortDirection: ModelSortDirection
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEventsBySlug(
    slug: $slug
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      venue
      startDateTime
      endDateTime
      hasGroupPricing
      hasRegistration
      closed
      capacity
      credentialCourseId
      slug
      tags_text
      publishAt
      isPrivate
      canAccessContent
      registrations
      status
      type
      deleted
      version
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      notificationEmails
      thankYouSubject
      confirmationSubject
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsBySlugQueryVariables,
  APITypes.ListEventsBySlugQuery
>;
export const searchEvents = /* GraphQL */ `query SearchEvents(
  $filter: SearchableEventFilterInput
  $sort: SearchableEventSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchEvents(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      name
      venue
      startDateTime
      endDateTime
      hasGroupPricing
      hasRegistration
      closed
      capacity
      credentialCourseId
      slug
      tags_text
      publishAt
      isPrivate
      canAccessContent
      registrations
      status
      type
      deleted
      version
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      notificationEmails
      thankYouSubject
      confirmationSubject
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchEventsQueryVariables,
  APITypes.SearchEventsQuery
>;
export const getEvent = /* GraphQL */ `query GetEvent($id: ID!) {
  getEvent(id: $id) {
    id
    name
    venue
    startDateTime
    endDateTime
    hasGroupPricing
    pricings {
      id
      name
      price
      tiers
      startDateTime
      endDateTime
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      __typename
    }
    hasRegistration
    registrationFields {
      id
      name
      label
      description
      placeholder
      defaultValue
      fieldType
      fieldOptions
      isRequired
      isHidden
      isLocked
      __typename
    }
    closed
    capacity
    credentialCourseId
    slug
    tags {
      id
      name
      __typename
    }
    tags_text
    publishAt
    preview {
      key
      region
      bucket
      __typename
    }
    isPrivate
    canAccessContent
    registrations
    thumbnail {
      key
      region
      bucket
      __typename
    }
    seo {
      title
      __typename
    }
    status
    type
    deleted
    version
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    vouchers {
      id
      code
      quantity
      redeemed
      discFixedPrice
      discPerc
      minPax
      maxPax
      startDateTime
      endDateTime
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      __typename
    }
    notificationEmails
    thankYouSubject
    thankYou {
      key
      region
      bucket
      __typename
    }
    confirmationSubject
    confirmation {
      key
      region
      bucket
      __typename
    }
    content {
      key
      region
      bucket
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetEventQueryVariables, APITypes.GetEventQuery>;
export const listEvents = /* GraphQL */ `query ListEvents(
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      venue
      startDateTime
      endDateTime
      hasGroupPricing
      hasRegistration
      closed
      capacity
      credentialCourseId
      slug
      tags_text
      publishAt
      isPrivate
      canAccessContent
      registrations
      status
      type
      deleted
      version
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      notificationEmails
      thankYouSubject
      confirmationSubject
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsQueryVariables,
  APITypes.ListEventsQuery
>;
export const getEventRegistration = /* GraphQL */ `query GetEventRegistration($id: ID!) {
  getEventRegistration(id: $id) {
    id
    data
    email
    status
    pricingTier {
      id
      name
      price
      tiers
      startDateTime
      endDateTime
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      __typename
    }
    eventRegistrationEventId
    stripeCustID
    stripeChargeID
    voucher {
      id
      code
      quantity
      redeemed
      discFixedPrice
      discPerc
      minPax
      maxPax
      startDateTime
      endDateTime
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      __typename
    }
    deleted
    version
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    event {
      id
      name
      venue
      startDateTime
      endDateTime
      hasGroupPricing
      hasRegistration
      closed
      capacity
      credentialCourseId
      slug
      tags_text
      publishAt
      isPrivate
      canAccessContent
      registrations
      status
      type
      deleted
      version
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      notificationEmails
      thankYouSubject
      confirmationSubject
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEventRegistrationQueryVariables,
  APITypes.GetEventRegistrationQuery
>;
export const listEventRegistrations = /* GraphQL */ `query ListEventRegistrations(
  $filter: ModelEventRegistrationFilterInput
  $limit: Int
  $nextToken: String
) {
  listEventRegistrations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      data
      email
      status
      eventRegistrationEventId
      stripeCustID
      stripeChargeID
      deleted
      version
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventRegistrationsQueryVariables,
  APITypes.ListEventRegistrationsQuery
>;
export const listEventRegistrationByEventId = /* GraphQL */ `query ListEventRegistrationByEventId(
  $eventRegistrationEventId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelEventRegistrationFilterInput
  $limit: Int
  $nextToken: String
) {
  listEventRegistrationByEventId(
    eventRegistrationEventId: $eventRegistrationEventId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      data
      email
      status
      eventRegistrationEventId
      stripeCustID
      stripeChargeID
      deleted
      version
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventRegistrationByEventIdQueryVariables,
  APITypes.ListEventRegistrationByEventIdQuery
>;
export const searchEventRegistrations = /* GraphQL */ `query SearchEventRegistrations(
  $filter: SearchableEventRegistrationFilterInput
  $sort: SearchableEventRegistrationSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchEventRegistrations(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      data
      email
      status
      eventRegistrationEventId
      stripeCustID
      stripeChargeID
      deleted
      version
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchEventRegistrationsQueryVariables,
  APITypes.SearchEventRegistrationsQuery
>;
export const getEventGalleryItem = /* GraphQL */ `query GetEventGalleryItem($id: ID!) {
  getEventGalleryItem(id: $id) {
    id
    eventID
    slug
    tags {
      id
      name
      __typename
    }
    tags_text
    publishAt
    preview {
      key
      region
      bucket
      __typename
    }
    isPrivate
    canAccessContent
    content {
      key
      region
      bucket
      __typename
    }
    thumbnail {
      key
      region
      bucket
      __typename
    }
    seo {
      title
      __typename
    }
    status
    type
    deleted
    version
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEventGalleryItemQueryVariables,
  APITypes.GetEventGalleryItemQuery
>;
export const listEventGalleryItems = /* GraphQL */ `query ListEventGalleryItems(
  $filter: ModelEventGalleryItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listEventGalleryItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      eventID
      slug
      tags_text
      publishAt
      isPrivate
      canAccessContent
      status
      type
      deleted
      version
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventGalleryItemsQueryVariables,
  APITypes.ListEventGalleryItemsQuery
>;
export const listMediasBySlug = /* GraphQL */ `query ListMediasBySlug(
  $slug: String
  $publishAt: ModelFloatKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMediaFilterInput
  $limit: Int
  $nextToken: String
) {
  listMediasBySlug(
    slug: $slug
    publishAt: $publishAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      exerpt
      slug
      tags_text
      publishAt
      isPrivate
      canAccessContent
      status
      type
      deleted
      version
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMediasBySlugQueryVariables,
  APITypes.ListMediasBySlugQuery
>;
export const listMediasByType = /* GraphQL */ `query ListMediasByType(
  $type: String
  $publishAt: ModelFloatKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMediaFilterInput
  $limit: Int
  $nextToken: String
) {
  listMediasByType(
    type: $type
    publishAt: $publishAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      exerpt
      slug
      tags_text
      publishAt
      isPrivate
      canAccessContent
      status
      type
      deleted
      version
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMediasByTypeQueryVariables,
  APITypes.ListMediasByTypeQuery
>;
export const searchMedias = /* GraphQL */ `query SearchMedias(
  $filter: SearchableMediaFilterInput
  $sort: SearchableMediaSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchMedias(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      exerpt
      slug
      tags_text
      publishAt
      isPrivate
      canAccessContent
      status
      type
      deleted
      version
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchMediasQueryVariables,
  APITypes.SearchMediasQuery
>;
export const getMedia = /* GraphQL */ `query GetMedia($id: ID!) {
  getMedia(id: $id) {
    id
    featuredImage {
      key
      region
      bucket
      __typename
    }
    exerpt
    slug
    tags {
      id
      name
      __typename
    }
    tags_text
    publishAt
    preview {
      key
      region
      bucket
      __typename
    }
    isPrivate
    canAccessContent
    thumbnail {
      key
      region
      bucket
      __typename
    }
    seo {
      title
      __typename
    }
    status
    type
    deleted
    version
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    content {
      key
      region
      bucket
      __typename
    }
    sidebar {
      id
      name
      deleted
      version
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetMediaQueryVariables, APITypes.GetMediaQuery>;
export const listMedias = /* GraphQL */ `query ListMedias(
  $filter: ModelMediaFilterInput
  $limit: Int
  $nextToken: String
) {
  listMedias(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      exerpt
      slug
      tags_text
      publishAt
      isPrivate
      canAccessContent
      status
      type
      deleted
      version
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMediasQueryVariables,
  APITypes.ListMediasQuery
>;
export const getTag = /* GraphQL */ `query GetTag($id: ID!) {
  getTag(id: $id) {
    id
    name
    deleted
    version
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTagQueryVariables, APITypes.GetTagQuery>;
export const listTags = /* GraphQL */ `query ListTags($filter: ModelTagFilterInput, $limit: Int, $nextToken: String) {
  listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      deleted
      version
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTagsQueryVariables, APITypes.ListTagsQuery>;
export const getSidebar = /* GraphQL */ `query GetSidebar($id: ID!) {
  getSidebar(id: $id) {
    id
    name
    items {
      label
      href
      __typename
    }
    deleted
    version
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSidebarQueryVariables,
  APITypes.GetSidebarQuery
>;
export const listSidebars = /* GraphQL */ `query ListSidebars(
  $filter: ModelSidebarFilterInput
  $limit: Int
  $nextToken: String
) {
  listSidebars(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      deleted
      version
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSidebarsQueryVariables,
  APITypes.ListSidebarsQuery
>;
export const getBodyOfKnowledge = /* GraphQL */ `query GetBodyOfKnowledge($id: ID!) {
  getBodyOfKnowledge(id: $id) {
    id
    bannerKey
    aboutKey
    partnersKey
    authors
    reviewers
    sections
    navigation
    fullRoles
    deleted
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBodyOfKnowledgeQueryVariables,
  APITypes.GetBodyOfKnowledgeQuery
>;
export const listBodyOfKnowledges = /* GraphQL */ `query ListBodyOfKnowledges(
  $filter: ModelBodyOfKnowledgeFilterInput
  $limit: Int
  $nextToken: String
) {
  listBodyOfKnowledges(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      bannerKey
      aboutKey
      partnersKey
      authors
      reviewers
      sections
      navigation
      fullRoles
      deleted
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBodyOfKnowledgesQueryVariables,
  APITypes.ListBodyOfKnowledgesQuery
>;
export const getBodyOfKnowledgeDocument = /* GraphQL */ `query GetBodyOfKnowledgeDocument($id: ID!) {
  getBodyOfKnowledgeDocument(id: $id) {
    id
    bokType
    contentKey
    parentID
    metadata
    title
    summaryKey
    introKey
    prevID
    nextID
    downloadKey
    deleted
    authors
    industries
    tags
    searchGroup
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBodyOfKnowledgeDocumentQueryVariables,
  APITypes.GetBodyOfKnowledgeDocumentQuery
>;
export const listBodyOfKnowledgeDocuments = /* GraphQL */ `query ListBodyOfKnowledgeDocuments(
  $filter: ModelBodyOfKnowledgeDocumentFilterInput
  $limit: Int
  $nextToken: String
) {
  listBodyOfKnowledgeDocuments(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      bokType
      contentKey
      parentID
      metadata
      title
      summaryKey
      introKey
      prevID
      nextID
      downloadKey
      deleted
      authors
      industries
      tags
      searchGroup
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBodyOfKnowledgeDocumentsQueryVariables,
  APITypes.ListBodyOfKnowledgeDocumentsQuery
>;
export const getBodyOfKnowledgeWhitelist = /* GraphQL */ `query GetBodyOfKnowledgeWhitelist($id: ID!) {
  getBodyOfKnowledgeWhitelist(id: $id) {
    id
    bokType
    email
    deleted
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBodyOfKnowledgeWhitelistQueryVariables,
  APITypes.GetBodyOfKnowledgeWhitelistQuery
>;
export const listBodyOfKnowledgeWhitelists = /* GraphQL */ `query ListBodyOfKnowledgeWhitelists(
  $filter: ModelBodyOfKnowledgeWhitelistFilterInput
  $limit: Int
  $nextToken: String
) {
  listBodyOfKnowledgeWhitelists(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      bokType
      email
      deleted
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBodyOfKnowledgeWhitelistsQueryVariables,
  APITypes.ListBodyOfKnowledgeWhitelistsQuery
>;
export const getDigitalCredential = /* GraphQL */ `query GetDigitalCredential($id: ID!) {
  getDigitalCredential(id: $id) {
    id
    membershipNo
    linkedEmail
    credentialType
    type
    batchNo
    status
    courseCode
    courseTitle
    certName
    certId
    certHash
    issuedOn
    expireOn
    openCertKey
    certKey
    badgeKey
    shareUrl
    deleted
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDigitalCredentialQueryVariables,
  APITypes.GetDigitalCredentialQuery
>;
export const listDigitalCredentials = /* GraphQL */ `query ListDigitalCredentials(
  $filter: ModelDigitalCredentialFilterInput
  $limit: Int
  $nextToken: String
) {
  listDigitalCredentials(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      membershipNo
      linkedEmail
      credentialType
      type
      batchNo
      status
      courseCode
      courseTitle
      certName
      certId
      certHash
      issuedOn
      expireOn
      openCertKey
      certKey
      badgeKey
      shareUrl
      deleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDigitalCredentialsQueryVariables,
  APITypes.ListDigitalCredentialsQuery
>;
export const searchDigitalCredentials = /* GraphQL */ `query SearchDigitalCredentials(
  $filter: SearchableDigitalCredentialFilterInput
  $sort: SearchableDigitalCredentialSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchDigitalCredentials(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      membershipNo
      linkedEmail
      credentialType
      type
      batchNo
      status
      courseCode
      courseTitle
      certName
      certId
      certHash
      issuedOn
      expireOn
      openCertKey
      certKey
      badgeKey
      shareUrl
      deleted
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchDigitalCredentialsQueryVariables,
  APITypes.SearchDigitalCredentialsQuery
>;
export const getVoucherRedemption = /* GraphQL */ `query GetVoucherRedemption($id: ID!) {
  getVoucherRedemption(id: $id) {
    id
    eventID
    eventRegistrationEventIds
    voucherCode
    voucher {
      id
      code
      quantity
      redeemed
      discFixedPrice
      discPerc
      minPax
      maxPax
      startDateTime
      endDateTime
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      __typename
    }
    deleted
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVoucherRedemptionQueryVariables,
  APITypes.GetVoucherRedemptionQuery
>;
export const listVoucherRedemptions = /* GraphQL */ `query ListVoucherRedemptions(
  $filter: ModelVoucherRedemptionFilterInput
  $limit: Int
  $nextToken: String
) {
  listVoucherRedemptions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      eventID
      eventRegistrationEventIds
      voucherCode
      deleted
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVoucherRedemptionsQueryVariables,
  APITypes.ListVoucherRedemptionsQuery
>;
export const getShortID = /* GraphQL */ `query GetShortID($id: ID!) {
  getShortID(id: $id) {
    id
    data
    expiration
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetShortIDQueryVariables,
  APITypes.GetShortIDQuery
>;
export const getDataMetadata = /* GraphQL */ `query GetDataMetadata($id: ID!) {
  getDataMetadata(id: $id) {
    id
    dataID
    type
    key
    value
    deleted
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    data {
      id
      type
      data
      deleted
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDataMetadataQueryVariables,
  APITypes.GetDataMetadataQuery
>;
export const listDataMetadatas = /* GraphQL */ `query ListDataMetadatas(
  $filter: ModelDataMetadataFilterInput
  $limit: Int
  $nextToken: String
) {
  listDataMetadatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      dataID
      type
      key
      value
      deleted
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDataMetadatasQueryVariables,
  APITypes.ListDataMetadatasQuery
>;
export const listDataMetadatasByType = /* GraphQL */ `query ListDataMetadatasByType(
  $type: String
  $keyValue: ModelDataMetadataByTypeCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDataMetadataFilterInput
  $limit: Int
  $nextToken: String
) {
  listDataMetadatasByType(
    type: $type
    keyValue: $keyValue
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      dataID
      type
      key
      value
      deleted
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDataMetadatasByTypeQueryVariables,
  APITypes.ListDataMetadatasByTypeQuery
>;
export const getData = /* GraphQL */ `query GetData($id: ID!) {
  getData(id: $id) {
    id
    type
    data
    deleted
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    __typename
  }
}
` as GeneratedQuery<APITypes.GetDataQueryVariables, APITypes.GetDataQuery>;
export const listDatas = /* GraphQL */ `query ListDatas(
  $filter: ModelDataFilterInput
  $limit: Int
  $nextToken: String
) {
  listDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      data
      deleted
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListDatasQueryVariables, APITypes.ListDatasQuery>;
export const getWhitelist = /* GraphQL */ `query GetWhitelist($id: ID!) {
  getWhitelist(id: $id) {
    id
    name
    email
    category
    sendEmail
    whitelistType
    group
    whitelistedBy
    whitelistedAt
    expiredAt
    deleted
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWhitelistQueryVariables,
  APITypes.GetWhitelistQuery
>;
export const listWhitelists = /* GraphQL */ `query ListWhitelists(
  $filter: ModelWhitelistFilterInput
  $limit: Int
  $nextToken: String
) {
  listWhitelists(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      email
      category
      sendEmail
      whitelistType
      group
      whitelistedBy
      whitelistedAt
      expiredAt
      deleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWhitelistsQueryVariables,
  APITypes.ListWhitelistsQuery
>;
export const listWhitelistsByTypeByEmail = /* GraphQL */ `query ListWhitelistsByTypeByEmail(
  $whitelistType: String
  $email: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelWhitelistFilterInput
  $limit: Int
  $nextToken: String
) {
  listWhitelistsByTypeByEmail(
    whitelistType: $whitelistType
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      email
      category
      sendEmail
      whitelistType
      group
      whitelistedBy
      whitelistedAt
      expiredAt
      deleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWhitelistsByTypeByEmailQueryVariables,
  APITypes.ListWhitelistsByTypeByEmailQuery
>;
export const listWhitelistsByEmailByType = /* GraphQL */ `query ListWhitelistsByEmailByType(
  $email: AWSEmail
  $whitelistType: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelWhitelistFilterInput
  $limit: Int
  $nextToken: String
) {
  listWhitelistsByEmailByType(
    email: $email
    whitelistType: $whitelistType
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      email
      category
      sendEmail
      whitelistType
      group
      whitelistedBy
      whitelistedAt
      expiredAt
      deleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWhitelistsByEmailByTypeQueryVariables,
  APITypes.ListWhitelistsByEmailByTypeQuery
>;
export const getProfile = /* GraphQL */ `query GetProfile {
  getProfile
}
` as GeneratedQuery<
  APITypes.GetProfileQueryVariables,
  APITypes.GetProfileQuery
>;
export const getPublicProfile = /* GraphQL */ `query GetPublicProfile($hash: String!, $token: String!) {
  getPublicProfile(hash: $hash, token: $token)
}
` as GeneratedQuery<
  APITypes.GetPublicProfileQueryVariables,
  APITypes.GetPublicProfileQuery
>;
export const getS3Hash = /* GraphQL */ `query GetS3Hash {
  getS3Hash
}
` as GeneratedQuery<APITypes.GetS3HashQueryVariables, APITypes.GetS3HashQuery>;
export const getImageHash = /* GraphQL */ `query GetImageHash {
  getImageHash
}
` as GeneratedQuery<
  APITypes.GetImageHashQueryVariables,
  APITypes.GetImageHashQuery
>;
export const getVideoHash = /* GraphQL */ `query GetVideoHash {
  getVideoHash
}
` as GeneratedQuery<
  APITypes.GetVideoHashQueryVariables,
  APITypes.GetVideoHashQuery
>;
export const listPressReleases = /* GraphQL */ `query ListPressReleases {
  listPressReleases
}
` as GeneratedQuery<
  APITypes.ListPressReleasesQueryVariables,
  APITypes.ListPressReleasesQuery
>;
export const listAnnualReport = /* GraphQL */ `query ListAnnualReport {
  listAnnualReport
}
` as GeneratedQuery<
  APITypes.ListAnnualReportQueryVariables,
  APITypes.ListAnnualReportQuery
>;
export const listSCSMagazine = /* GraphQL */ `query ListSCSMagazine {
  listSCSMagazine
}
` as GeneratedQuery<
  APITypes.ListSCSMagazineQueryVariables,
  APITypes.ListSCSMagazineQuery
>;
export const listSCSInTheNews = /* GraphQL */ `query ListSCSInTheNews {
  listSCSInTheNews
}
` as GeneratedQuery<
  APITypes.ListSCSInTheNewsQueryVariables,
  APITypes.ListSCSInTheNewsQuery
>;
export const listENewsletter = /* GraphQL */ `query ListENewsletter {
  listENewsletter
}
` as GeneratedQuery<
  APITypes.ListENewsletterQueryVariables,
  APITypes.ListENewsletterQuery
>;
export const listFolder = /* GraphQL */ `query ListFolder($path: String) {
  listFolder(path: $path)
}
` as GeneratedQuery<
  APITypes.ListFolderQueryVariables,
  APITypes.ListFolderQuery
>;
export const getERPLink = /* GraphQL */ `query GetERPLink($input: GetERPLinkQueryInput!) {
  getERPLink(input: $input)
}
` as GeneratedQuery<
  APITypes.GetERPLinkQueryVariables,
  APITypes.GetERPLinkQuery
>;
export const getCertificates = /* GraphQL */ `query GetCertificates {
  getCertificates {
    id
    certificate_name
    certificate_programme
    certificate_title_grade
    expiry
    years_in_it
    state
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCertificatesQueryVariables,
  APITypes.GetCertificatesQuery
>;
export const getPastPurchases = /* GraphQL */ `query GetPastPurchases {
  getPastPurchases {
    id
    purchase_date
    purchase_items {
      id
      name
      category
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPastPurchasesQueryVariables,
  APITypes.GetPastPurchasesQuery
>;
export const getVoucher = /* GraphQL */ `query GetVoucher($input: GetVoucherQueryInput!) {
  getVoucher(input: $input) {
    id
    code
    quantity
    redeemed
    discFixedPrice
    discPerc
    minPax
    maxPax
    startDateTime
    endDateTime
    createdAt
    createdBy
    updatedAt
    updatedBy
    deleted
    deletedAt
    deletedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVoucherQueryVariables,
  APITypes.GetVoucherQuery
>;
export const exportRegistrationList = /* GraphQL */ `query ExportRegistrationList($input: ExportRegistrationListQueryInput!) {
  exportRegistrationList(input: $input) {
    id
    data
    email
    status
    pricingTier {
      id
      name
      price
      tiers
      startDateTime
      endDateTime
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      __typename
    }
    eventRegistrationEventId
    stripeCustID
    stripeChargeID
    voucher {
      id
      code
      quantity
      redeemed
      discFixedPrice
      discPerc
      minPax
      maxPax
      startDateTime
      endDateTime
      createdAt
      createdBy
      updatedAt
      updatedBy
      deleted
      deletedAt
      deletedBy
      __typename
    }
    deleted
    version
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    event {
      id
      name
      venue
      startDateTime
      endDateTime
      hasGroupPricing
      hasRegistration
      closed
      capacity
      credentialCourseId
      slug
      tags_text
      publishAt
      isPrivate
      canAccessContent
      registrations
      status
      type
      deleted
      version
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy
      notificationEmails
      thankYouSubject
      confirmationSubject
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ExportRegistrationListQueryVariables,
  APITypes.ExportRegistrationListQuery
>;
export const getBOK = /* GraphQL */ `query GetBOK($input: GetBOKQueryInput!) {
  getBOK(input: $input)
}
` as GeneratedQuery<APITypes.GetBOKQueryVariables, APITypes.GetBOKQuery>;
export const getBOKDocument = /* GraphQL */ `query GetBOKDocument($input: GetBOKDocumentQueryInput!) {
  getBOKDocument(input: $input)
}
` as GeneratedQuery<
  APITypes.GetBOKDocumentQueryVariables,
  APITypes.GetBOKDocumentQuery
>;
export const getWhitelistOTP = /* GraphQL */ `query GetWhitelistOTP($input: GetWhitelistOTPQueryInput!) {
  getWhitelistOTP(input: $input)
}
` as GeneratedQuery<
  APITypes.GetWhitelistOTPQueryVariables,
  APITypes.GetWhitelistOTPQuery
>;
export const getLinkIdentitiesOTP = /* GraphQL */ `query GetLinkIdentitiesOTP($email: AWSEmail!) {
  getLinkIdentitiesOTP(email: $email)
}
` as GeneratedQuery<
  APITypes.GetLinkIdentitiesOTPQueryVariables,
  APITypes.GetLinkIdentitiesOTPQuery
>;
export const getLinkIdentitiesCredentials = /* GraphQL */ `query GetLinkIdentitiesCredentials($email: AWSEmail!, $otp: String!) {
  getLinkIdentitiesCredentials(email: $email, otp: $otp)
}
` as GeneratedQuery<
  APITypes.GetLinkIdentitiesCredentialsQueryVariables,
  APITypes.GetLinkIdentitiesCredentialsQuery
>;
export const getSSGCourseDetails = /* GraphQL */ `query GetSSGCourseDetails($courseID: String!) {
  getSSGCourseDetails(courseID: $courseID)
}
` as GeneratedQuery<
  APITypes.GetSSGCourseDetailsQueryVariables,
  APITypes.GetSSGCourseDetailsQuery
>;
export const searchBoK = /* GraphQL */ `query SearchBoK($search: String!, $searchBy: String!, $searchGroup: String!) {
  searchBoK(search: $search, searchBy: $searchBy, searchGroup: $searchGroup)
}
` as GeneratedQuery<APITypes.SearchBoKQueryVariables, APITypes.SearchBoKQuery>;
