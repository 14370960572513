import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "nodep-date-input-polyfill";

import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { CONFIG } from "scs-sdk";
import { UserContextProvider } from "./contexts/UserContext";
import App from "./App";
import { WebsiteContextProvider } from "./contexts/WebsiteContext";
import { Amplify, Auth } from "aws-amplify";

import { init as sentryInit } from "@sentry/react";

import PWA from "./PWA";
import TutorialUserLogin from "./components/TutorialUserLogin";
import TutorialWelcome from "./components/TutorialWelcome";
import TutorialEvent from "./components/TutorialEvent";

import maintenanceImage from "./assets/images/maintenance.png";

import "react-multi-carousel/lib/styles.css";
import "font-awesome/css/font-awesome.min.css";

import "./styles.scss";
import "./wit.css";

Amplify.configure(CONFIG);
Auth.configure({
  userPoolId: CONFIG["aws_user_pools_id"],
  userPoolWebClientId: CONFIG["aws_user_pools_web_client_id"],
  region: CONFIG["aws_cognito_region"],
  identityPoolId: CONFIG["aws_cognito_identity_pool_id"],
  mandatorySignIn: false,
  authenticationFlowType: "USER_PASSWORD_AUTH",
});

if (process.env.NODE_ENV === "production") {
  sentryInit({
    dsn: "https://2fc5e01c291e4c50ac879f476d405539@o88999.ingest.sentry.io/5207123",
  });
  console.log = () => {};
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Router>
    <PWA>
      {process.env.REACT_APP_MAINTENANCE === "true" ? (
        <div
          className="row mb-0 pb-0"
          style={{ marginTop: "5%", marginLeft: "10%", marginRight: "10%" }}
        >
          <div
            className="mb-4 pb-0 col-lg-12 col-md-12 col-sm-12"
            style={{ textAlign: "center" }}
          >
            <img
              src={`https://${CONFIG.aws_user_files_s3_bucket}.s3-${CONFIG.aws_user_files_s3_bucket_region}.amazonaws.com/public/uploads/logo.png`}
              alt="logo"
            />
          </div>
          <div
            className="mt-4 pb-0 col-lg-6 col-md-12 col-sm-12"
            style={{ textAlign: "left" }}
          >
            <p style={{ color: "#014C98" }}>
              <span
                style={{ fontSize: 50, fontFamily: "Helvetica, sans-serif" }}
              >
                Sorry,
                <br />
              </span>
              <span
                style={{ fontSize: 30, fontFamily: "Helvetica, sans-serif" }}
              >
                We are under maintenance...
              </span>
              <br />
              <span
                style={{ fontSize: 30, fontFamily: "Helvetica, sans-serif" }}
              >
                be back soon!
              </span>
            </p>
          </div>
          <div
            className="col-lg-6 col-md-12 col-sm-12"
            style={{ marginTop: 150, maxWidth: "100%" }}
          >
            <img
              src={maintenanceImage}
              alt="Maintenance"
              style={{
                textAlign: "right",
                marginBottom: 0,
                paddingBottom: 0,
                width: "100%",
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </div>
        </div>
      ) : (
        <UserContextProvider>
          <WebsiteContextProvider>
            <App />
            {/* <TutorialWelcome />
          <TutorialUserLogin />
          <TutorialEvent /> */}
          </WebsiteContextProvider>
        </UserContextProvider>
      )}
    </PWA>
  </Router>
);
