import React from "react";
import { Nav, Container, Row, Col } from "react-bootstrap";
import { NavHashLink } from "react-router-hash-link";

interface IHeaderLinkProps {
  index: number;
  openIndex: number;
  nav: any;
  onClick: () => void;
}

const HeaderLink: React.FC<IHeaderLinkProps> = (props) => {
  const { index, openIndex, nav, onClick } = props;

  let showSubMenuTitle =
    nav.children
      .map((_nav: any) => _nav.title)
      .filter((title: string) => title && title !== " ").length > 0;

  return (
    <>
      <Nav.Link
        className="col-auto navlink text-letter-spacing-1 active align-self-center text-uppercase text-wrap text-center"
        onClick={onClick}
      >
        {nav.title}
        {nav.label ? (
          <>
            &nbsp;
            <span
              className="badge"
              style={{
                color: "white",
                background: nav.labelColor,
              }}
            >
              {nav.label}
            </span>
          </>
        ) : null}
      </Nav.Link>
      {openIndex === index ? (
        <div
          className="w-100 position-absolute"
          style={{
            left: 0,
            top: 80,
            zIndex: 9999,
          }}
          onMouseLeave={onClick}
        >
          <div
            className="w-100 p-1 pt-4 pb-4"
            style={{ background: "#344d76" }}
          >
            <Container className="h-auto">
              <Row className="w-100 border-bottom border-white mb-2">
                <Col
                  xs="auto"
                  className="text-letter-spacing-2 h4 fw-bold text-uppercase text-white"
                >
                  {nav.title}
                </Col>
              </Row>
              <Row className="w-100 h-auto">
                {nav.children.map((menu: any, i: number) => (
                  <Col key={i} md="3" className="mt-2">
                    {showSubMenuTitle ? (
                      <Row className="w-100 mb-4" style={{ height: 15 }}>
                        <Col className="h6 fw-bold text-white">
                          <u>{menu.title}</u>
                          {menu.label ? (
                            <>
                              &nbsp;
                              <span
                                className="badge"
                                style={{
                                  color: "white",
                                  background: menu.labelColor,
                                }}
                              >
                                {menu.label}
                              </span>
                            </>
                          ) : null}
                        </Col>
                      </Row>
                    ) : null}
                    {menu.children.map((_childMenu: any, i: number) => (
                      <Row
                        key={i}
                        className={[
                          showSubMenuTitle ? "mt-2" : "",
                          "mb-2",
                        ].join(" ")}
                      >
                        <Col className="font-weight-normal text-white">
                          {_childMenu.href ? (
                            <>
                              {_childMenu.href.includes("#") ||
                              !_childMenu.hrefType ||
                              _childMenu.hrefType.value === "PAGE" ? (
                                <NavHashLink
                                  to={_childMenu.href}
                                  className="text-white"
                                >
                                  {_childMenu.title}
                                  {_childMenu.label ? (
                                    <>
                                      &nbsp;
                                      <span
                                        className="badge"
                                        style={{
                                          color: "white",
                                          background: _childMenu.labelColor,
                                        }}
                                      >
                                        {_childMenu.label}
                                      </span>
                                    </>
                                  ) : null}
                                </NavHashLink>
                              ) : null}
                              {_childMenu.hrefType &&
                              _childMenu.hrefType.value ===
                                "EXTERNAL URL (SECURE)" ? (
                                <a
                                  href={"https://" + _childMenu.href}
                                  className="text-white"
                                >
                                  {_childMenu.title}
                                  {_childMenu.label ? (
                                    <>
                                      &nbsp;
                                      <span
                                        className="badge"
                                        style={{
                                          color: "white",
                                          background: _childMenu.labelColor,
                                        }}
                                      >
                                        {_childMenu.label}
                                      </span>
                                    </>
                                  ) : null}
                                </a>
                              ) : null}
                              {_childMenu.hrefType &&
                              _childMenu.hrefType.value === "EXTERNAL URL" ? (
                                <a
                                  href={"http://" + _childMenu.href}
                                  className="text-white"
                                >
                                  {_childMenu.title}
                                  {_childMenu.label ? (
                                    <>
                                      &nbsp;
                                      <span
                                        className="badge"
                                        style={{
                                          color: "white",
                                          background: _childMenu.labelColor,
                                        }}
                                      >
                                        {_childMenu.label}
                                      </span>
                                    </>
                                  ) : null}
                                </a>
                              ) : null}
                            </>
                          ) : (
                            <a href="#" className="text-white">
                              {_childMenu.title}
                              {_childMenu.label ? (
                                <>
                                  &nbsp;
                                  <span
                                    className="badge"
                                    style={{
                                      color: "white",
                                      background: _childMenu.labelColor,
                                    }}
                                  >
                                    {_childMenu.label}
                                  </span>
                                </>
                              ) : null}
                            </a>
                          )}
                        </Col>
                      </Row>
                    ))}
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </div>
      ) : null}
    </>
  );
  // return (
  //   <Grid item>
  //     <NavLink
  //       className="dropdownButton"
  //       activeClassName="active"
  //       to={props.menu.href ? props.menu.href : "#"}
  //     >
  //       {props.menu.title}
  //     </NavLink>
  //     <HeaderDropDown menu={props.menu} />
  //   </Grid>
  // );
};

export default HeaderLink;
