import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import "./StickySideWidget.css";
import { UserContext } from "../../contexts/UserContext";
import moment from "moment";
import ERPLink from "../ERPLink";

export default function StickySideWidget(props: any) {
  const { user }: any = React.useContext(UserContext);
  const renderContent = () => {
    if (!user) {
      return (
        <div className="sticky-widget">
          <p className="label">
            <Link to="/membership">Become a Member</Link>
          </p>
        </div>
      );
    } else if (
      user &&
      user.membership_expiry &&
      moment(user.membership_expiry, "DD-MM-YYYY").diff(moment(), "days") <= 30
    ) {
      return (
        <div className="sticky-widget">
          <p className="label">
            <ERPLink path="/page/open/renewal/member">Renew Membership</ERPLink>
          </p>
        </div>
      );
    } else {
      return (
        <div className="sticky-widget">
          <p className="label">
            <ERPLink path="/page/open/memberdetail">
              Update Member Profile
            </ERPLink>
          </p>
        </div>
      );
    }
  };

  return <>{renderContent()}</>;
}
