import React from "react";
import { Modal, Row, Col, Container, Button } from "react-bootstrap";

const ComponentModalUpdateNotice = () => {
  const [show, setShow] = React.useState(false);

  const onHide = () => {
    setShow(false);
  };

  const onReload = () => {
    window.location.reload();
  };

  React.useEffect(() => {
    // @ts-ignore
    if (window.document.documentMode) {
      setShow(true);
    }
  }, []);

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="text-center">
            <Col>
              <Modal.Title>We have upgraded!</Modal.Title>
            </Col>
          </Row>
          <Row className="text-muted text-center my-4">
            <Col>
              <p>
                It looks like we have upgraded our systems while you were gone!
                Please reload the webpage to load our latest updates!.
              </p>
            </Col>
          </Row>
          <Row className="text-center my-4">
            <Col>
              <Button variant="outline-primary" onClick={onReload}>
                Click here to reload!
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ComponentModalUpdateNotice;
