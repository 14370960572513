import {
  ListConfigurationsQueryVariables,
  UpdateConfigurationMutationVariables,
  CreateConfigurationMutationVariables,
  GetConfigurationQueryVariables,
} from "../API";
import { query, mutation } from "gql-query-builder";
import { buildVariables } from "../libs/GraphQueryBuilder";
import { API } from "@aws-amplify/api";
import { queryList } from "../libs/APIManager";

// Query Generator Helper

export function getConfigurationQuery(id: string, fields: any[]) {
  const variables = buildVariables<GetConfigurationQueryVariables>(
    {
      id,
    },
    [{ key: "id", name: "ID!" }]
  );

  return query({
    operation: "result: getConfiguration",
    fields,
    variables,
  });
}

export function listConfigurationQuery(
  fields: any[],
  _variables?: ListConfigurationsQueryVariables
) {
  if (!_variables) {
    _variables = {
      filter: {
        deleted: { eq: false },
      },
      nextToken: undefined,
    };
  } else {
    if (!_variables.filter) {
      _variables.filter = {};
    }
    _variables.filter.deleted = { eq: false };
    _variables.nextToken = _variables.nextToken || undefined;
  }

  const variables = buildVariables<ListConfigurationsQueryVariables>(
    _variables,
    [{ key: "filter", name: "ModelConfigurationFilterInput" }]
  );

  return query({
    operation: "result: listConfigurations",
    fields,
    variables,
  });
}
export function updateConfigurationQuery(
  fields: any[],
  _variables: UpdateConfigurationMutationVariables
) {
  const variables = buildVariables<UpdateConfigurationMutationVariables>(
    _variables,
    [{ key: "input", name: "UpdateConfigurationInput!" }]
  );

  return mutation({
    operation: "result: updateConfiguration",
    fields,
    variables,
  });
}

export function createConfigurationQuery(
  fields: any[],
  _variables: CreateConfigurationMutationVariables
) {
  const variables = buildVariables<CreateConfigurationMutationVariables>(
    _variables,
    [{ key: "input", name: "CreateConfigurationInput!" }]
  );

  return mutation({
    operation: "result: createConfiguration",
    fields,
    variables,
  });
}

export function deleteConfigurationQuery(
  fields: any[],
  id: string,
  version: number
) {
  const variables = buildVariables<UpdateConfigurationMutationVariables>(
    { input: { id, expectedVersion: version, deleted: true } },
    [{ key: "input", name: "UpdateConfigurationInput!" }]
  );

  return mutation({
    operation: "result: updateConfiguration",
    fields,
    variables,
  });
}

// API Helper

export async function getConfiguration(id: string, fields: any[]) {
  const query = getConfigurationQuery(id, fields);

  const res: any = await API.graphql(query);

  return res.data.result;
}

export async function createConfiguration(
  fields: any[],
  _variables: CreateConfigurationMutationVariables
) {
  const query = createConfigurationQuery(fields, _variables);

  await API.graphql(query);
}

export function listConfiguration(
  fields: any[],
  _variables?: ListConfigurationsQueryVariables
) {
  if (!fields.includes("nextToken")) {
    fields.push("nextToken");
  }
  const query = listConfigurationQuery(fields, _variables);

  return queryList(query);
}

export async function updateConfiguration(
  fields: any[],
  _variables: UpdateConfigurationMutationVariables
) {
  const query = updateConfigurationQuery(fields, _variables);

  await API.graphql(query);
}

export async function deleteConfiguration(
  fields: any[],
  id: string,
  version: number
) {
  const query = deleteConfigurationQuery(fields, id, version);

  await API.graphql(query);
}
