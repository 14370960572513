import React, { useEffect, useContext } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Link } from "react-router-dom";
import HeaderLink from "./HeaderLink";
import { UserContext } from "../contexts/UserContext";
import { WebsiteContext } from "../contexts/WebsiteContext";
import ERPLink from "./ERPLink";
import { Nav, Navbar, NavDropdown, Container, Badge } from "react-bootstrap";
import HeaderLinkMobile from "./HeaderLinkMobile";
import useWindowDimensions from "../libs/useWindowDimensions";
import moment from "moment";
import { getConfiguration } from "scs-sdk/src/managers/ConfigurationManager";
import { v4 } from "uuid";
import { CONFIG } from "scs-sdk";

const Header: React.FC<RouteComponentProps> = (props) => {
  const { user } = useContext(UserContext);
  const {
    location: { pathname },
  } = props;

  const [expandMobile, setExpandMobile] = React.useState(false);
  const { siteTitle, navbar, navbarMobile } = useContext(WebsiteContext);
  const { width } = useWindowDimensions();
  const [currentOpenMenuIndex, setCurrentOpenMenuIndex] = React.useState(-1);
  const [showRenewMembershipDaysBefore, setShowRenewMembershipDaysBefore] =
    React.useState(90);

  useEffect(() => {
    setCurrentOpenMenuIndex(-1);
  }, [pathname]);

  const onMenuClick = (index: number) => () => {
    if (currentOpenMenuIndex === index) {
      setCurrentOpenMenuIndex(-1);
      return;
    }

    setCurrentOpenMenuIndex(index);
  };

  const onMobileMenuSelect = () => {
    setExpandMobile(false);
  };

  const init = async () => {
    try {
      const daysBeforeConfig = await getConfiguration(
        "SHOW_RENEW_MEMBERSHIP_DAYS_BEFORE",
        ["data"]
      );

      if (daysBeforeConfig) {
        setShowRenewMembershipDaysBefore(parseInt(daysBeforeConfig.data));
      }
    } catch (err: any) {}
  };

  useEffect(() => {
    init();
  }, []);

  const expiry = user
    ? moment(user.membership_expiry, "DD-MM-YYYY")
    : undefined;

  const expired = expiry ? expiry.diff(moment(), "days") <= 0 : undefined;

  const show_renew_membership =
    expired ||
    (expiry && expiry.diff(moment(), "days") <= showRenewMembershipDaysBefore);

  const generateMyAccount = () => {
    return (
      <NavDropdown
        className={
          width <= 768
            ? "text-center"
            : "my-account text-letter-spacing-1 mx-3 align-self-center active"
        }
        title="MY ACCOUNT"
        id="my-account"
      >
        <NavDropdown.Header className="border-bottom text-center">
          {user.name} -{" "}
          <Link to="/account/logout" className="text-danger">
            Logout
          </Link>
          {user &&
            user.membership_grade !== "Guest" &&
            user.membership_grade !== "Honorary Fellow" &&
            !expired && (
              <p className="text-muted">
                {user.membership_grade} till{" "}
                {moment(user.membership_expiry, "DD-MM-YYYY").format(
                  "MMM YYYY"
                )}
              </p>
            )}
          {user && user.membership_grade === "Honorary Fellow" && (
            <p className="text-muted">{user.membership_grade}</p>
          )}
        </NavDropdown.Header>
        {user && user.membership_expiry && show_renew_membership ? (
          <NavDropdown.Item
            as={ERPLink}
            id="renew-membership"
            path="/page/open/renewal/member"
            className="border-bottom text-success text-center"
          >
            Renew Membership
          </NavDropdown.Item>
        ) : null}
        <NavDropdown.Item
          as={Link}
          to="/account/profile"
          className="border-bottom text-center"
        >
          STAR@SCS{" "}
          <Badge bg="danger" size="sm">
            NEW
          </Badge>
        </NavDropdown.Item>
        <NavDropdown.Item
          as={ERPLink}
          id="update-profile"
          path="/page/open/memberdetail"
          className="border-bottom text-center"
        >
          Update Member Profile
        </NavDropdown.Item>
        {user.membership_grade !== "Guest" ? (
          <>
            <NavDropdown.Item
              as={ERPLink}
              id="join-scs-chapter-sigs"
              path="/page/open/memberdetail#scs_chapter"
              className="border-bottom text-center"
            >
              Join SCS Chapters/SIGs
            </NavDropdown.Item>
          </>
        ) : null}
        <NavDropdown.Item
          as={ERPLink}
          id="access-invoices-receipts"
          path="/page/open/invoice/list"
          className="border-bottom text-center"
        >
          Access Invoices/Receipts
        </NavDropdown.Item>
        <NavDropdown.Item
          as={ERPLink}
          path="/page/open/draft/list"
          className="border-bottom text-center"
        >
          Access Draft Application
        </NavDropdown.Item>
        {user.membership_grade !== "Guest" ? (
          <>
            <NavDropdown.Item
              as={ERPLink}
              path="/page/open/memberprofile"
              className="border-bottom text-center"
            >
              View Membership eCard
            </NavDropdown.Item>
          </>
        ) : null}
        <NavDropdown.Divider />
        <NavDropdown.Item
          href="https://drive.google.com/drive/folders/1LPtGBycuRFveNKWc_tCaakGbTVXZD-ZE?usp=sharing"
          target="_blank"
          className="border-bottom text-center"
        >
          View Portal’s Navigation Guide
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item
          as={Link}
          to="/account/change-password"
          className="border-bottom text-center"
        >
          Change Password
        </NavDropdown.Item>
      </NavDropdown>
    );
  };

  React.useEffect(() => {
    const lnscript = document.createElement("script");

    lnscript.src = "https://platform.linkedin.com/in.js";
    lnscript.type = "text/javascript";
    lnscript.innerHTML = " lang: en_US";

    const script = document.createElement("script");
    script.type = "IN/FollowCompany";
    script.dataset.id = "4814041";
    script.dataset.counter = "none";

    document.getElementById("lnfollow")?.appendChild(lnscript);
    document.getElementById("lnfollow")?.appendChild(script);
  }, []);

  return (
    <>
      <Navbar
        variant="dark"
        className="d-none d-md-flex"
        style={{ background: "#00418b" }}
      >
        <Container>
          {user ? (
            <span className="text-white small ms-3">
              You are logged in as {user.name}.
            </span>
          ) : null}

          <div className="me-auto">&nbsp;</div>
          <Nav>
            {user ? (
              generateMyAccount()
            ) : (
              <>
                {process.env.REACT_APP_ERP_ENABLED === "true" ? (
                  <Link
                    className="text-letter-spacing-1 mx-3 text-uppercase align-self-center active"
                    to={
                      "/account/login?redirect=" +
                      (pathname !== "/account/login"
                        ? pathname + window.location.search
                        : "/")
                    }
                    style={{ color: "white" }}
                  >
                    LOGIN
                  </Link>
                ) : null}
              </>
            )}
            <Link
              className="text-letter-spacing-1 mx-3 text-uppercase align-self-center active"
              to="/about"
              style={{ color: "white" }}
            >
              ABOUT US
            </Link>
            {moment().isSameOrAfter(moment("2023-05-05T20:00:00+08:00")) ? (
              <Link
                className="text-letter-spacing-1 mx-3 text-uppercase align-self-center active"
                to="/membership/starscs"
                style={{ color: "white" }}
              >
                STAR@SCS
              </Link>
            ) : (
              <Link
                className="text-letter-spacing-1 mx-3 text-uppercase align-self-center active"
                to="/contact"
                style={{ color: "white" }}
              >
                CONTACTS
              </Link>
            )}
            <div id="lnfollow" />
          </Nav>
        </Container>
      </Navbar>
      <Navbar
        className={[
          "navbarMobile bg-white",
          width <= 768 ? "sticky-top" : "position-top",
          "position-lg-flex border-bottom",
        ].join(" ")}
        expand="lg"
        expanded={expandMobile}
        onToggle={() => {
          setExpandMobile(!expandMobile);
        }}
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              src={`https://${CONFIG.aws_user_files_s3_bucket}.s3-${CONFIG.aws_user_files_s3_bucket_region}.amazonaws.com/public/uploads/logo.png`}
              alt={siteTitle}
              height={
                moment().isSameOrAfter(moment("2023-05-05T19:45:00+08:00")) ||
                CONFIG.aws_user_files_s3_bucket.includes("dev")
                  ? 40
                  : 60
              }
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav">
            <Nav className="w-100 justify-content-around">
              {width <= 768 && (
                <>
                  {navbarMobile.map((nav, i) => (
                    <HeaderLinkMobile
                      key={i}
                      nav={nav}
                      onClose={onMobileMenuSelect}
                    />
                  ))}
                  {user ? (
                    generateMyAccount()
                  ) : (
                    <>
                      {process.env.REACT_APP_ERP_ENABLED === "true" ? (
                        <HeaderLinkMobile
                          nav={{
                            id: v4(),
                            title: "Login",
                            href:
                              "/account/login?redirect=" +
                              (window.location.pathname !== "/account/login"
                                ? window.location.pathname +
                                  window.location.search
                                : "/"),
                            label: "",
                            labelColor: "",
                            children: [],
                          }}
                          onClose={onMobileMenuSelect}
                        />
                      ) : null}
                    </>
                  )}
                </>
              )}
              {width > 768 &&
                navbar.map((nav, i) => (
                  <HeaderLink
                    key={i}
                    index={i}
                    openIndex={currentOpenMenuIndex}
                    nav={nav}
                    onClick={onMenuClick(i)}
                  />
                ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default withRouter(Header);
