import React from "react";
import { register, unregister } from "./serviceWorker";
import { Modal, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const PWA: React.FC = (props) => {
  const { pathname } = useLocation();
  const [waitingWorker, setWaitingWorker] = React.useState<any>(undefined);
  const [newVersionAvailable, setNewVersionAvailable] = React.useState(false);

  const onServiceWorkerUpdate = (registration: any) => {
    setWaitingWorker(registration?.waiting);
    setNewVersionAvailable(true);
  };

  const onForceRefreshClicked = () => {
    unregister();
    window.location.reload();
  };

  const onRefreshClicked = () => {
    waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
    setNewVersionAvailable(false);
    window.location.reload();
  };

  React.useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      unregister();
      //register({ onUpdate: onServiceWorkerUpdate });
    }
  }, [pathname]);

  return (
    <>
      {props.children}
      {newVersionAvailable ? (
        <Modal show={true} backdrop="static" keyboard={false}>
          <Modal.Header>Updates Detected!</Modal.Header>
          <Modal.Body>
            We have updated our application! Please refresh the page to load the
            latest version.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={onForceRefreshClicked}>
              Click here to force update.
            </Button>
            <Button variant="primary" onClick={onRefreshClicked}>
              Refresh
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};

export default PWA;
