import React from "react";
import ComponentModalUpdateNotice from "./components/ComponentModalUpdateNotice";

interface ISuspenseComponentProps {
  componentPath: string;
  props: any;
}

const SuspenseComponent: React.FC<ISuspenseComponentProps> = (props) => {
  const { componentPath, props: componentProps, children } = props;

  try {
    const Component = React.lazy(() => import(`${componentPath}`));

    return (
      <React.Suspense fallback={null}>
        <Component {...componentProps}>{children}</Component>
      </React.Suspense>
    );
  } catch (err: any) {
    if (process.env.NODE_ENV === "development") {
      console.log(err);
      return (
        <React.Suspense fallback={null}>
          <p>Failed to load component {componentPath}</p>
        </React.Suspense>
      );
    }
    return <ComponentModalUpdateNotice />;
  }
};

export default SuspenseComponent;
