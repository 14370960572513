import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";

interface IHeaderLinkMobileProps {
  nav: any;
  onClose: () => void;
}

const HeaderLinkMobile: React.FC<IHeaderLinkMobileProps> = (props) => {
  const { nav, onClose } = props;

  const showSubMenu =
    nav.children
      .map((_nav: any) => _nav.title)
      .filter((title: string) => title && title !== " ").length > 0;

  if (nav.children && nav.children.length > 0) {
    return (
      <NavDropdown
        id={nav.id}
        title={
          <>
            {nav.title}
            {nav.label ? (
              <>
                &nbsp;
                <span
                  className="badge"
                  style={{
                    color: "white",
                    background: nav.labelColor,
                  }}
                >
                  {nav.label}
                </span>
              </>
            ) : null}
          </>
        }
        className="text-center"
      >
        {nav.children.map((_child: any, i: number) => (
          <div key={i}>
            {_child.children && _child.children.length > 0 ? (
              <>
                {showSubMenu ? (
                  <NavDropdown
                    id={_child.id}
                    title={
                      <>
                        {_child.title}
                        {_child.label ? (
                          <>
                            &nbsp;
                            <span
                              className="badge"
                              style={{
                                color: "white",
                                background: _child.labelColor,
                              }}
                            >
                              {_child.label}
                            </span>
                          </>
                        ) : null}
                      </>
                    }
                    className="text-center"
                  >
                    {_child.children.map((_menu: any, i: number) => {
                      
                      const isExternalLink =
                      _menu.hrefType?.label === "URL"
                      
                      if (isExternalLink) {
                        let props: any = {
                          as: HashLink,
                          to: _menu.href,
                        }
                        
                        props = {
                          href: (_menu.hrefType.value === "EXTERNAL URL" ? "http://" : "https://") + _menu.href,
                          target: "_blank",
                          rel: "noopener noreferrer"
                        }

                        return (
                          <div key={i}>
                            <NavDropdown.Item                                                            
                              className="border-bottom text-center"
                              onSelect={onClose}
                              {...props}                              
                            >
                              {_menu.title}
                            </NavDropdown.Item>
                          </div>
                        );
                      }
                      
                      return(
                        <div key={i}>
                          <NavDropdown.Item
                            as={HashLink}
                            to={_menu.href}
                            className="text-wrap text-center"
                            onSelect={onClose}
                          >
                            {_menu.title}
                            {_menu.label ? (
                              <>
                                &nbsp;
                                <span
                                  className="badge"
                                  style={{
                                    color: "white",
                                    background: _menu.labelColor,
                                  }}
                                >
                                  {_menu.label}
                                </span>
                              </>
                            ) : null}
                          </NavDropdown.Item>
                          <NavDropdown.Divider />
                        </div>
                      )

                    })}
                  </NavDropdown>
                ) : (
                  <>
                    {_child.children.map((_menu: any, i: number) => {
                      const isExternalLink =
                        /https?:\/\//.exec(_menu.href) !== null;
                      let props: any = {
                        as: HashLink,
                        to: _menu.href,
                      };

                      if (isExternalLink) {
                        props = {
                          href: _menu.href,
                          target: "_blank",
                        };
                      }

                      return (
                        <NavDropdown.Item
                          key={i}
                          {...props}
                          className="border-bottom text-center"
                          onSelect={onClose}
                        >
                          {_menu.title}
                        </NavDropdown.Item>
                      );
                    })}
                  </>
                )}
              </>
            ) : (
              <Nav.Link
                as={HashLink}
                to={_child.href}
                className="text-letter-spacing-1 active align-self-center text-uppercase fw-bold"
                onSelect={onClose}
              >
                {_child.title}
                {_child.label ? (
                  <>
                    &nbsp;
                    <span
                      className="badge"
                      style={{
                        color: "white",
                        background: _child.labelColor,
                      }}
                    >
                      {_child.label}
                    </span>
                  </>
                ) : null}
              </Nav.Link>
            )}
          </div>
        ))}
      </NavDropdown>
    );
  }

  return (
    <Nav.Link
      as={HashLink}
      to={nav.href}
      className=" text-letter-spacing-1 active align-self-center text-uppercase fw-bold"
      onClick={onClose}
    >
      {nav.title}
      {nav.label ? (
        <>
          &nbsp;
          <span
            className="badge"
            style={{
              color: "white",
              background: nav.labelColor,
            }}
          >
            {nav.label}
          </span>
        </>
      ) : null}
    </Nav.Link>
  );
};

export default HeaderLinkMobile;
