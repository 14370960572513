import React from "react";
import { GridLoader } from "react-spinners";

export default function LoadingIndicator(props: any) {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: 500 }}
    >
      <GridLoader color="var(--primary-color)" size={25} />
    </div>
  );
}
