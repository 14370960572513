import React from "react";
import "./App.css";
import Routes from "./Routes";
import ScrollToTop from "./components/ScrollToTop";
import StickySideWidget from "./components/StickySideWidget/StickySideWidget";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Helmet } from "react-helmet";
import { WebsiteContext } from "./contexts/WebsiteContext";
import ErrorBoundary from "./components/ErrorBoundary";
import TagManager from "react-gtm-module";

const App = () => {
  const { siteTitle } = React.useContext(WebsiteContext);

  const init = async () => {
    if (process.env.NODE_ENV === "production") {
      // Google Tag Manager
      TagManager.initialize({
        gtmId: "GTM-NRSQPH7",
      });

      // iMailcampaign
      try {
        let mt = (key: string, value: string) => {};
        const mtLoad = (
          w: any,
          d: any,
          t: any,
          u: any,
          n: any,
          a?: any,
          m?: any
        ) => {
          w["MauticTrackingObject"] = n;
          w[n] =
            w[n] ||
            function () {
              (w[n].q = w[n].q || []).push(arguments);
            };
          a = d.createElement(t);
          m = d.getElementsByTagName(t)[0];
          a.async = 1;
          a.src = u;
          m.parentNode.insertBefore(a, m);
        };

        mtLoad(
          window,
          document,
          "script",
          "https://singaporecomputersociety.imail-host.com/mtc.js",
          mt
        );

        mt("send", "pageview");
      } catch (err: any) {
        console.log(err);
      }

      // Linkedin Campaigns
      try {
        const _window: any = window;
        _window._linkedin_data_partner_ids =
          _window._linkedin_data_partner_ids || [];
        _window._linkedin_data_partner_ids.push("1023179");
        _window._linkedin_data_partner_ids.push("4535684");
        var s2: any = document.getElementsByTagName("script")[0];
        var b2: any = document.createElement("script");
        b2.type = "text/javascript";
        b2.async = true;
        b2.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s2.parentNode.insertBefore(b2, s2);
      } catch (err: any) {
        console.log(err);
      }

      // Facebook Pixels
      try {
        let fbq: (key: string, value: string) => void | undefined = () => {};
        const fbLoad = (
          f: any,
          b: any,
          e: any,
          v: any,
          n?: any,
          t?: any,
          s?: any
        ) => {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = "2.0";
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        };

        fbLoad(
          window,
          document,
          "script",
          "https://connect.facebook.net/en_US/fbevents.js",
          fbq
        );

        console.log("fbq", fbq);

        if (fbq) {
          fbq("init", "2478319535728055");
          fbq("init", "3308020319465014");
          fbq("track", "PageView");
        }
      } catch (err: any) {
        console.log(err);
      }
    }

    setTimeout(() => {
      //@ts-ignore
      window.prerenderReady = true;
    }, 1000 * 20);
  };

  React.useEffect(() => {
    init();
  }, []);
  return (
    <>
      <div className="App">
        <Helmet>
          <title>{siteTitle}</title>
        </Helmet>
        <Header />
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
        <Footer />
        <ScrollToTop />
        <StickySideWidget />
      </div>
      {process.env.NODE_ENV === "production" && (
        <>
          <noscript>
            <img
              height="1"
              width="1"
              style={{ display: "none" }}
              alt=""
              src="https://px.ads.linkedin.com/collect/?pid=1023179&fmt=gif"
            />
          </noscript>
          <noscript>
            <img
              height="1"
              width="1"
              style={{ display: "none" }}
              alt=""
              src="https://px.ads.linkedin.com/collect/?pid=4535684&fmt=gif"
            />
          </noscript>
          <noscript>
            <img
              alt=""
              height="1"
              width="1"
              src="https://www.facebook.com/tr?id=2478319535728055&ev=PageView&noscript=1"
            />
          </noscript>
          <noscript>
            <img
              alt=""
              height="1"
              width="1"
              src="https://www.facebook.com/tr?id=3308020319465014&ev=PageView&noscript=1"
            />
          </noscript>
        </>
      )}
    </>
  );
};

export default App;
