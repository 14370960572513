import React, { FC } from "react";
import {
  RootRenderHTML,
  RenderHTMLInstruction,
} from "scs-sdk/src/components/RootRenderHTML";
import { Link } from "react-router-dom";
import SuspenseComponent from "../SuspenseComponent";
import { Button } from "react-bootstrap";

interface IProps {
  html: string;
}

const scrollWithOffset = (href: any) => (event:any) => {  
  event.preventDefault()
  const el = document.getElementById(href.replace("#", ""));

  if (el) {
    const yCoordinate = el!.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: yCoordinate, behavior: "smooth" });
  }
};

const instructions: RenderHTMLInstruction[] = [
  {
    shouldProcessNode: (node) => {
      const result =
        node.name &&
        node.name === "div" &&
        node.attribs &&
        node.attribs["data-component"];
      return result;
    },
    processNode: (node, children, index) => {
      const props: { [key: string]: string } = {};

      for (const key of Object.keys(node.attribs)) {
        if (key.startsWith("data-") && key !== "data-component") {
          props[key.replace("data-", "")] = node.attribs[key];
        }
        if (key === "class") {
          props["className"] = node.attribs[key];
        }
      }

      const component = node.attribs["data-component"];

      return (
        <SuspenseComponent
          key={index}
          componentPath={
            component.includes("./") ? component : "./components/" + component
          }
          props={props}
        >
          {children}
        </SuspenseComponent>
      );
    },
  },
  {
    shouldProcessNode: function (node) {
      const result =
        node.name &&
        node.name === "a" &&
        node.attribs &&
        node.attribs["href"] &&
        !node.attribs["href"].includes("http") &&
        !node.attribs["href"].startsWith("tel:") &&
        !node.attribs["href"].startsWith("mailto:");
      return result;
    },
    processNode: function (node, children, index) {
      const href = node.attribs["href"];
      const nodeClass = node.attribs["class"];
      
      if (href.startsWith("#") && !href.includes("footnote")) {
        return (
          <Button
            variant="link"
            className={nodeClass}
            onClick={scrollWithOffset(href)}
          >
            {children}
          </Button>
        );
      } else if(href.startsWith("#") && href.includes("footnote")){
        return (
        <a className={nodeClass} href={href} onClick={scrollWithOffset(href)}>
          {children}
        </a>)
      }
      return (
        <Link className={nodeClass} to={href}>
          {children}
        </Link>
      );
    },
  },
];

export const RenderHTML: FC<IProps> = (props) => {
  const { html } = props;
  return <RootRenderHTML instructions={instructions} html={html} />;
};
