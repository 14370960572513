import React, { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { ClipLoader } from "react-spinners";
import { useLocation, useRouteMatch } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

// const SERVER_URL = `https://membership.scs.org.sg${process.env.ENV === "dev" ? "/dev" : "/scs"}`;

const ERPLink: React.FC<{
  id?: string;
  className?: string;
  erpoffdisplay?: string;
  display?: string; // ERP_OFF_HIDDEN, AUTH_HIDDEN, UNAUTH_HIDDEN
  mode?: string; // REDIRECT_ON_LOAD
  path: string;
  unauthenticatedlabel?: string;
  style?: React.CSSProperties;
}> = (props) => {
  const location = useLocation();
  let {
    id,
    className,
    erpoffdisplay,
    display,
    mode,
    path,
    unauthenticatedlabel,
    style,
    children,
  } = props;
  const [loading, setLoading] = React.useState(false);
  const { isAuthenticated, getERPLink } = useContext(UserContext);

  const publicPaths = [
    "/page/open/membership",
    "/page/event/all",
    "/page/event/cstp",
    "/page/event/cciso",
    "/page/event/csqa",
    "/page/event/scs",
    "/page/list/bok",
  ];
  const mixedPaths = ["/page/list/bok"];
  const isPublicPath = publicPaths.includes(path);
  const isMixedPath = mixedPaths.includes(path);

  const onClick = async (e: any) => {
    e.preventDefault();

    await goToERP();
  };

  const goToERP = async () => {
    setLoading(true);
    let url = null;

    if (process.env.REACT_APP_ERP_ENABLED === "false") {
      switch (path) {
        case "/page/open/membership":
          url =
            "https://forms.office.com/Pages/ResponsePage.aspx?id=R-CRSuJ3iUGTsIv7sk5eg2ykIgAysyxAth4sJsWXQIRUMFMyMjBRUVlNSFZYR1VBSzgwVURPTUVDNCQlQCN0PWcu";
          break;
        case "/page/open/renewal/member":
          url =
            "https://forms.office.com/Pages/ResponsePage.aspx?id=R-CRSuJ3iUGTsIv7sk5eg2ykIgAysyxAth4sJsWXQIRUM1VNUFpMU0U4TUpOMk84TlE4UFVTVFc1NSQlQCN0PWcu";
          break;
        case "/page/open/memberdetail":
          url =
            "https://forms.office.com/Pages/ResponsePage.aspx?id=R-CRSuJ3iUGTsIv7sk5eg2ykIgAysyxAth4sJsWXQIRUM1NXOU5aUUJIVDY0MVg2M01FMEtZMVlVViQlQCN0PWcu";
          break;
        case "/page/open/memberdetail#scs_chapter":
          url =
            "https://forms.office.com/Pages/ResponsePage.aspx?id=R-CRSuJ3iUGTsIv7sk5eg6FRhTzFjhBNlYqv_UvFMJNUOFpDVUZQWkw5MExaR0dIUlpDSlY4T0lFNi4u";
          break;
      }
    } else {
      if (path === "portal" && window.location.pathname === "/portal") {
        const urlParams = new URLSearchParams(window.location.search);
        const _url = urlParams.get("url");

        if (!_url) {
          return;
        }
        path = _url;
        url = await getERPLink(
          path,
          window.location.protocol +
            "//" +
            window.location.hostname +
            (window.location.port ? ":" + window.location.port : "")
        );
      } else {
        url = await getERPLink(path);
      }
    }

    if (url) {
      window.location.href = url;
    }
  };

  React.useEffect(() => {
    if (isAuthenticated && mode && mode.includes("REDIRECT_ON_LOAD")) {
      goToERP();
    }
  }, []);

  if (isAuthenticated && path === "portal") {
    return <p>...and we are set!</p>;
  }

  if (
    process.env.REACT_APP_ERP_ENABLED === "false" &&
    (erpoffdisplay === "hidden" ||
      (display && display.includes("ERP_OFF_HIDDEN")))
  ) {
    return null;
  }

  if (isAuthenticated && display && display.includes("AUTH_HIDDEN")) {
    return null;
  }

  if (!isAuthenticated && display && display.includes("UNAUTH_HIDDEN")) {
    return null;
  }

  if (
    process.env.REACT_APP_ERP_ENABLED === "true" &&
    !isPublicPath &&
    !isMixedPath &&
    !isAuthenticated
  ) {
    return (
      <HashLink
        id={id}
        className={className}
        to={
          "/account/login?redirect=" +
          (location.pathname !== "/account/login"
            ? location.pathname + window.location.search
            : "/")
        }
        style={{ ...style, ...{ cursor: "pointer" } }}
        href="#"
      >
        {unauthenticatedlabel || "Please login to continue."}
      </HashLink>
    );
  }

  return (
    <div
      id={id}
      className={className}
      onClick={onClick}
      style={{ ...style, ...{ cursor: "pointer" } }}
    >
      {children} {loading ? <ClipLoader color="white" size={10} /> : null}
    </div>
  );
};

export default ERPLink;
