import API, { graphqlOperation } from "@aws-amplify/api";
import {
  getERPLink as _getERPLink,
  getPastPurchases as _getPastPurchases,
  getCertificates as _getCertificates,
} from "../graphql/queries";
import { GetERPLinkQueryVariables } from "../API";
import { query } from "gql-query-builder";

export async function getERPLink(variables: GetERPLinkQueryVariables) {
  const res: any = await API.graphql(graphqlOperation(_getERPLink, variables));
  const data = res.data.getERPLink;

  return data;
}

export async function getCertificates() {
  const res: any = await API.graphql(graphqlOperation(_getCertificates));
  const data = res.data.getCertificates;

  return data;
}

export function getPastPurchasesQuery(fields: any[]) {
  return query({
    operation: "result: getPastPurchases",
    fields,
  });
}

export async function getPastPurchases(fields: any[]) {
  const query = getPastPurchasesQuery(fields);
  const res: any = await API.graphql(query);
  const data = res.data.result;

  return data;
}
