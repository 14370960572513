import { BrowserStorageCache } from "@aws-amplify/cache";

export const getCachedAPI = async <T>(
  key: string,
  options: {
    priority?: number;
    expires?: number;
    callback: () => Promise<T>;
    postCallback?: (data: T) => Promise<T>;
  }
): Promise<T> => {
  const { callback, postCallback, priority, expires } = options;

  let data =
    process.env.NODE_ENV === "production"
      ? await BrowserStorageCache.getItem(key)
      : null;

  if (!data) {
    data = await callback();

    if (postCallback) {
      data = await postCallback(data);
    }

    BrowserStorageCache.setItem(key, data, {
      priority: priority,
      expires: expires || new Date().getTime() + 1000 * 60 * 1,
    });
  }

  return data;
};
