import React from "react";
import { withRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import { captureException as sentryCaptureException } from "@sentry/react";

class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({
        hasError: false,
      });
    }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
    sentryCaptureException(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container>
          <h1>Oh no! Something went wrong.</h1>
          <p>
            Click <a href="/">here</a> to go back to homepage.
          </p>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
