export function buildVariables<T>(
  _variables: T & { [key: string]: any },
  types: { key: string; name: string }[]
) {
  const variables : any = {};

  for (const key of Object.keys(_variables)) {
    variables[key] = {
      value: _variables[key],
    };

    const type = types.find((_type) => _type.key === key);

    if (type) {
      variables[key].type = type.name;
    }
  }

  return variables;
}
