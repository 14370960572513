import { query, mutation } from "gql-query-builder";
import {
  ListCommunitysQueryVariables,
  ListCommunitiesBySlugQueryVariables,
  UpdateCommunityMutationVariables,
  CreateCommunityMutationVariables,
} from "../API";
import { buildVariables } from "../libs/GraphQueryBuilder";
import { API } from "@aws-amplify/api";
import { queryList, queryListWithCallback } from "../libs/APIManager";

// Query Generator Helper

export function createCommunityQuery(
  fields: any[],
  _variables: CreateCommunityMutationVariables
) {
  const variables = buildVariables<CreateCommunityMutationVariables>(
    _variables,
    [{ key: "input", name: "CreateCommunityInput!" }]
  );

  return mutation({
    operation: "result: createCommunity",
    fields,
    variables,
  });
}

export function updateCommunityQuery(
  fields: any[],
  _variables: UpdateCommunityMutationVariables
) {
  const variables = buildVariables<UpdateCommunityMutationVariables>(
    _variables,
    [{ key: "input", name: "UpdateCommunityInput!" }]
  );

  return mutation({
    operation: "result: updateCommunity",
    fields,
    variables,
  });
}

export function listCommunitysQuery(
  fields: any[],
  _variables?: ListCommunitysQueryVariables
) {
  if (!_variables) {
    _variables = {
      filter: {
        deleted: { eq: false },
      },
      nextToken: undefined,
    };
  } else {
    if (!_variables.filter) {
      _variables.filter = {};
    }
    _variables.filter.deleted = { eq: false };
    _variables.nextToken = _variables.nextToken || undefined;
  }

  const variables = buildVariables<ListCommunitysQueryVariables>(_variables, [
    { key: "filter", name: "ModelCommunityFilterInput" },
  ]);

  return query({
    operation: "result: listCommunitys",
    fields,
    variables,
  });
}

export function getBySlugQuery(
  fields: any[],
  _variables?: ListCommunitiesBySlugQueryVariables
) {
  if (!_variables) {
    _variables = {
      filter: {
        deleted: { eq: false },
      },
      nextToken: undefined,
    };
  } else {
    if (!_variables.filter) {
      _variables.filter = {};
    }
    _variables.filter.deleted = { eq: false };
    _variables.nextToken = _variables.nextToken || undefined;
  }

  const variables = buildVariables<ListCommunitiesBySlugQueryVariables>(
    _variables,
    [
      { key: "filter", name: "ModelCommunityFilterInput" },
      { key: "sortDirection", name: "ModelSortDirection" },
    ]
  );

  return query({
    operation: "result: listCommunitiesBySlug",
    fields,
    variables,
  });
}

export function deleteCommunityQuery(
  fields: any[],
  id: string,
  version: number
) {
  const variables = buildVariables<UpdateCommunityMutationVariables>(
    { input: { id, expectedVersion: version, deleted: true } },
    [{ key: "input", name: "UpdateCommunityInput!" }]
  );

  return mutation({
    operation: "result: updateCommunity",
    fields,
    variables,
  });
}

// API Helper

export async function createCommunity(
  fields: any[],
  _variables: CreateCommunityMutationVariables
) {
  const query = createCommunityQuery(fields, _variables);

  await API.graphql(query);
}

export function listCommunitys(
  fields: any[],
  _variables?: ListCommunitysQueryVariables
) {
  if (!fields.includes("nextToken")) {
    fields.push("nextToken");
  }
  const query = { ...listCommunitysQuery(fields, _variables) };

  return queryList(query);
}

export function listCommunitysWithCallback(
  fields: any[],
  onLoad: () => void,
  onNewItemsLoaded: (items: any[], errors: any[]) => void,
  onComplete: () => void,
  _variables: ListCommunitysQueryVariables = {}
) {
  if (!fields.includes("nextToken")) {
    fields.push("nextToken");
  }
  const query = { ...listCommunitysQuery(fields, _variables) };

  queryListWithCallback(query, onLoad, onNewItemsLoaded, onComplete);
}

export async function getBySlug(
  fields: any[],
  _variables: ListCommunitiesBySlugQueryVariables = {}
) {
  if (!fields.includes("nextToken")) {
    fields.push("nextToken");
  }
  const query = { ...getBySlugQuery(fields, _variables) };

  const res: any = await API.graphql(query);
  const result = res.data.result.items[0];

  return result;
}

export async function deleteCommunitys(
  fields: any[],
  id: string,
  version: number
) {
  const query = deleteCommunityQuery(fields, id, version);

  await API.graphql(query);
}
