import React from "react";
const HtmlToReact = require("html-to-react");
const HtmlToReactParser = require("html-to-react").Parser;
const DEFAULT_NODE_PROCESSOR = new HtmlToReact.ProcessNodeDefinitions(React)
  .processDefaultNode;

export interface RenderHTMLInstruction {
  replaceChildren?: boolean;
  shouldProcessNode: (node: any) => boolean;
  processNode: (node: any, children: any, index: any) => JSX.Element;
}

interface IProps {
  instructions: RenderHTMLInstruction[];
  html: string;
}

export const RootRenderHTML: React.FC<IProps> = (props) => {
  const { html, instructions } = props;

  instructions.push({
    shouldProcessNode: function (_) {
      return true;
    },
    processNode: DEFAULT_NODE_PROCESSOR,
  });

  const isValidNode = function () {
    return true;
  };

  const htmlToReactParser = new HtmlToReactParser();
  const Element = htmlToReactParser.parseWithInstructions(
    html,
    isValidNode,
    instructions
  );

  return Element;
};
