import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import "./Footer.css";

import FacebookIcon from "../../assets/images/facebook.png";
import LinkedInIcon from "../../assets/images/linkedin.png";
import TelegramIcon from "../../assets/images/telegram.png";
import YoutubeIcon from "../../assets/images/youtube.png";
import EmailIcon from "../../assets/images/email.png";
import { WebsiteContext } from "../../contexts/WebsiteContext";

const Footer = () => {
  const { footer } = React.useContext(WebsiteContext);

  return (
    <div className="container-fluid footer">
      <div className="row">
        <div className="col-md-1"></div>

        {footer.map((footerSection, index) => {
          return (
            <div className="col-md-2 d-none d-md-block" key={index}>
              <h4 className="footerTitle">{footerSection.title}</h4>
              <ul className="footerLinks">
                {footerSection.children.map((footerLink: any, i: number) => {
                  return (
                    <li key={i}>
                      {footerLink.hrefType &&
                        footerLink.hrefType.value === "EXTERNAL URL (SECURE)" ? (
                          <a
                            href={"https://" + footerLink.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="footerLink"
                          >
                            {footerLink.title}
                          </a>
                        ) : footerLink.hrefType &&
                          footerLink.hrefType.value === "EXTERNAL URL" ? (
                            <a
                              href={"http://" + footerLink.href}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="footerLink"
                            >
                              {footerLink.title}
                            </a>
                          ) : footerLink.href.includes("#") ? (
                            <HashLink
                              smooth
                              to={footerLink.href}
                              className="footerLink"
                            >
                              {footerLink.title}
                            </HashLink>
                          ) : (
                              <Link to={footerLink.href} className="footerLink">
                                {footerLink.title}
                              </Link>
                            )}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>

      <div className="row">
        <div className="col-md-1"></div>
        <div className="col">
          <div className="footerText" style={{ marginTop: 20 }}>
            <p style={{ marginBottom: 5 }}>
              Copyright © 1967-{new Date().getFullYear()} Singapore Computer
              Society. All Rights Reserved.
            </p>
            <p style={{ marginTop: 0 }}>
              The Leading Infocomm and Digital Media Professional Society in
              Singapore
            </p>
            <ul className="list-inline">
              <li className="list-inline-item">
                <a href="https://www.facebook.com/SingaporeComputerSociety" target="_blank">
                  <img src={FacebookIcon} width="40" />
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.linkedin.com/company/singapore-computer-society" target="_blank">
                  <img src={LinkedInIcon} width="40" />
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://t.me/singaporecomputersociety" target="_blank">
                  <img src={TelegramIcon} width="40" />
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.youtube.com/c/singaporecomputersociety" target="_blank">
                  <img src={YoutubeIcon} width="40" />
                </a>
              </li>
              <li className="list-inline-item">
                <a href="mailto:scs.secretariat@scs.org.sg">
                  <img src={EmailIcon} width="40" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
  );
};

export default Footer;
