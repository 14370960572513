import { query, mutation } from "gql-query-builder";
import {
  ListMediasByTypeQueryVariables,
  ListMediasBySlugQueryVariables,
  CreateMediaMutationVariables,
  UpdateMediaMutationVariables,
} from "../API";
import { buildVariables } from "../libs/GraphQueryBuilder";
import { API } from "@aws-amplify/api";
import { queryList, queryListWithCallback } from "../libs/APIManager";

// Query Generator Helper

export function listPagesQuery(
  fields: any[],
  _variables?: ListMediasByTypeQueryVariables
) {
  if (!_variables) {
    _variables = {
      type: "PAGE",
      filter: {
        deleted: { eq: false },
      },
      nextToken: undefined,
    };
  } else {
    _variables.type = "PAGE";

    if (!_variables.filter) {
      _variables.filter = {};
    }
    _variables.filter.deleted = { eq: false };
    _variables.nextToken = _variables.nextToken || undefined;
  }

  const variables = buildVariables<ListMediasByTypeQueryVariables>(_variables, [
    { key: "filter", name: "ModelMediaFilterInput" },
    { key: "publishAt", name: "ModelFloatKeyConditionInput" },
    { key: "sortDirection", name: "ModelSortDirection" },
  ]);

  return query({
    operation: "result: listMediasByType",
    fields,
    variables,
  });
}

export function getMediaBySlugQuery(
  fields: any[],
  _variables?: ListMediasBySlugQueryVariables
) {
  if (!_variables) {
    _variables = {
      filter: {
        deleted: { eq: false },
      },
      nextToken: undefined,
    };
  } else {
    if (!_variables.filter) {
      _variables.filter = {};
    }
    _variables.filter.deleted = { eq: false };
    _variables.nextToken = _variables.nextToken || undefined;
  }

  const variables = buildVariables<ListMediasBySlugQueryVariables>(_variables, [
    { key: "filter", name: "ModelMediaFilterInput" },
    { key: "publishAt", name: "ModelFloatKeyConditionInput" },
    { key: "sortDirection", name: "ModelSortDirection" },
  ]);

  return query({
    operation: "result: listMediasBySlug",
    fields,
    variables,
  });
}

export function createPageQuery(
  fields: any[],
  _variables: CreateMediaMutationVariables
) {
  const variables = buildVariables<CreateMediaMutationVariables>(_variables, [
    { key: "input", name: "CreateMediaInput!" },
  ]);

  return mutation({
    operation: "result: createMedia",
    fields,
    variables,
  });
}

export function updatePageQuery(
  fields: any[],
  _variables: UpdateMediaMutationVariables
) {
  const variables = buildVariables<UpdateMediaMutationVariables>(_variables, [
    { key: "input", name: "UpdateMediaInput!" },
  ]);

  return mutation({
    operation: "result: updateMedia",
    fields,
    variables,
  });
}

export function deletePageQuery(fields: any[], id: string, version: number) {
  const variables = buildVariables<UpdateMediaMutationVariables>(
    { input: { id, expectedVersion: version, deleted: true } },
    [{ key: "input", name: "UpdateMediaInput!" }]
  );

  return mutation({
    operation: "result: updateMedia",
    fields,
    variables,
  });
}

export function approvePageQuery(fields: any[], id: string, version: number) {
  const variables = buildVariables<UpdateMediaMutationVariables>(
    { input: { id, expectedVersion: version, status: "APPROVED" } },
    [{ key: "input", name: "UpdateMediaInput!" }]
  );

  return mutation({
    operation: "result: updateMedia",
    fields,
    variables,
  });
}

// API Helper

export function listPages(
  fields: any[],
  _variables?: ListMediasByTypeQueryVariables
) {
  if (!fields.includes("nextToken")) {
    fields.push("nextToken");
  }
  const query = listPagesQuery(fields, _variables);

  return queryList(query);
}

export function listPagesWithCallback(
  fields: any[],
  onLoad: () => void,
  onNewItemsLoaded: (items: any[], errors: any[]) => void,
  onComplete: () => void,
  _variables?: ListMediasByTypeQueryVariables
) {
  if (!fields.includes("nextToken")) {
    fields.push("nextToken");
  }
  const query = listPagesQuery(fields, _variables);

  queryListWithCallback(query, onLoad, onNewItemsLoaded, onComplete);
}

export async function getPageBySlug(
  fields: any[],
  _variables?: ListMediasBySlugQueryVariables
) {
  const query = getMediaBySlugQuery(fields, _variables);

  const res: any = await API.graphql(query);
  const page = res.data.result.items[0];

  return page;
}

export async function createPage(
  fields: any[],
  _variables: CreateMediaMutationVariables
) {
  const query = createPageQuery(fields, _variables);

  await API.graphql(query);
}

export async function updatePage(
  fields: any[],
  _variables: UpdateMediaMutationVariables
) {
  const query = updatePageQuery(fields, _variables);

  await API.graphql(query);
}

export async function deletePage(fields: any[], id: string, version: number) {
  const query = deletePageQuery(fields, id, version);

  await API.graphql(query);
}

export async function approvePage(fields: any[], id: string, version: number) {
  const query = approvePageQuery(fields, id, version);

  await API.graphql(query);
}
